import  { ReactFlow,
    Controls, Background,
    useNodesState, 
    useEdgesState, 
  addEdge, 
    reconnectEdge,
    Handle, 
    Position,
    useReactFlow,
    applyEdgeChanges,
    applyNodeChanges,
    ReactFlowProvider
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import 'styles/react_flow.css'

import { formatDateUser } from 'modules/datetime';

import { 
  useState, 
  useRef, 
  forwardRef, 
  useMemo, 
  useEffect, 
  useCallback,
  useImperativeHandle
} from 'react';

import {
  Box,
  Flex,
  Stack,
  HStack,
  StackDivider,
  Text,
  StatGroup,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container,
  Checkbox
} from '@chakra-ui/react';



//import ELK from 'elkjs/lib/elk.bundled.js';
import { layout } from './bipartite_layout';

// TODO: replace by stat
function TitledLowerView({title, underline, children}) {

  return (
      <Stack
        spacing={0}
        align='stretch'
        min-width='100px'
      >
        <Text fontSize='sm' as='u'>{title}</Text>
        <Flex ml='4' bg={underline && underline}>
          {children}
        </Flex>
      </Stack>
  );
}

function TitledSideView({title, children}) {

  return (
      <HStack
        spacing={2}
        align='stretch'
        width='120px'
        pl='4'
      >
        <Text fontSize='sm' as='u'>{title}</Text>
        <Flex>
          {children}
        </Flex>
      </HStack>
  );
}

// node formatter from raw data
function format_node(row) {
  
  let {id, from, ...other} = row;
  
  // format
  return {
    id : id.toString(),
    type : from === 'BLOT' ? 'blotNode' : 'dbNode',
    data : {...other, 
      from, 
      label : id
    },
    draggable: false
  };
}

// node formatter when updating a single state
function format_update_node(node, new_data) {
  
  let {data, ...other} = node;
  
  // format
  return {
    data : new_data,
    ...other
  };
}

// edge formatter from raw data
function format_edge(edge) {

  return {
    id : edge.blot_index + '_' + edge.db_index,
    source : edge.blot_index.toString(),
    target : edge.db_index.toString(),
    sourcePosition: 'left',
    targetPosition: 'right',
    arrowHeadType: 'arrow', 
  };
}


// Custom BLOT and Database node
export function VerifierNode({id, data, from, isConnectable }) {
  
  // updator of the data
  const { 
    setNodes,
    updateNodeData
  } = useReactFlow();
  

  // determine handle position and type
  const handle_position = from === 'BLOT' ? Position.Right : Position.Left;
  const handle_type = from === 'BLOT' ? "source" : "target";


  const buy_gradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', 'orange');
  const sell_gradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', '#98FB98');

  const title_gradient = data.trade_side === 'buy' ? buy_gradient : sell_gradient;

  const mkt_price_formatted = data.market_price_value ? 
    data.market_price_value.toFixed(3) : 
    (data.market_price >= 0) ? '+' + data.market_price : data.market_price;

  // QTY, MKT_PRICE, BROKER, SET DT view
  //borderWidth='1px' borderRadius='lg'
  return (
    <div>
      <Handle 
        type={handle_type}
        position={handle_position} 
        isConnectable={isConnectable} 
      >
        {from}
      </Handle>
      
      <Stack 
        divider={<StackDivider borderColor='gray.200' />}
        spacing={1}
        align='stretch'
      >
        <Flex width='100%' bgGradient={title_gradient}>
          <Text ml='2' mt='2' as='b' fontSize='1xl'> {data.trade_side.toUpperCase()} </Text>
        </Flex>
        
        <div className='node-wrapper'>
        <SimpleGrid columns={4} spacing={2} p='1'>
       
          <TitledLowerView title='Broker'>
            {data.broker_name}
          </TitledLowerView>
          <TitledLowerView title='Set Dt'>
            {formatDateUser(data.settlement_datetime)}
          </TitledLowerView>
          <TitledLowerView 
            title='Market price' 
            underline={!data.market_price_value && '#ddd'}
          >
            {mkt_price_formatted} {data.currency}
          </TitledLowerView>
          <TitledLowerView title='Quantity (M)'>
            {data.quantity}
          </TitledLowerView>

        </SimpleGrid>
        </div>
      </Stack>
    </div>
  );
}

function blotNode ({id, data, isConnectable}) {
  return  <VerifierNode id={id} data={data} isConnectable={isConnectable} from={'BLOT'} />; }

function dbNode ({id, data, isConnectable}) {
  return <VerifierNode id={id} data={data} isConnectable={isConnectable} from={'DB'} />;
}


const nodeTypes = 
  {
    blotNode : blotNode,
    dbNode : dbNode  
  };



// TODO: adapt distance as function of the user
const NODE_DISTANCE = 100;
const BLOT_POSITION_X =  -250;
const DB_POSITION_X =  500;

const NODE_WIDTH = 550;
const NODE_HEIGHT = 120;


// layout handler
const getLayoutedElements = async (nodes, edges) => {

  // TODO: style nodes using the custom component
  const color_side = (node) => {

    if (node.data.trade_side === 'buy') {
      return 'orange';
    } else if (node.data.trade_side === 'sell') {
      return '#98FB98';
    } else {
      return '#00000000';
    }
  }

  // BLOT left
  var blot_nodes = nodes.filter(node => node.data.from === 'BLOT' && node.type !== 'group')

  // compute height
  const blot_height = blot_nodes.length * NODE_DISTANCE;

  const blot_group = {
    id: 'blot_group',
    type: 'group',
    data: { label: null },
  };

  // DB right
  var db_nodes = nodes.filter(node => node.data.from === 'DB' && node.type !== 'group')
  
  // compute height
  const db_height = db_nodes.length * NODE_DISTANCE;

  
  const db_group = {
    id: 'db_group',
    type: 'group',
    data: { label: null },
  };

  var children = [{...blot_group, children : blot_nodes}];

  if (db_nodes.length > 0) {
    children.push({...db_group, children : db_nodes});
  }
  
  const graph = {
    layoutOptions : {
      groupSpacing: 200,
      nodeSpacing: 15,
      padding: 20,
      offset: { x : -80, y : 0},
      nodeWidth: NODE_WIDTH,
      nodeHeight: NODE_HEIGHT,
    },
    children: children,
  }


  const layout_nodes = await layout(graph)
    .then(({ children, ...rest }) => {

      const blot_size = children[0].children.length;
      const db_size = (children.length > 1) ? children[1].children.length : 0;

      //  manage blot group node
      children[0].position = { x : children[0].x , y : children[0].y };
      delete children[0].x;
      delete children[0].y;
      
      // offset
      if (blot_size < db_size) {
        children[0].position.y += 50;
      }

      // manage blot nodes
      children[0].children.forEach((node) => {
        
        // assign position
        node.position = { x: node.x, y: node.y };

        // remove un-necessary fields
        delete node.x;
        delete node.y;

        // assign parent
        node.parentId = 'blot_group';
        node.extent = 'parent';
      });

      if (children.length < 2) {
        // return only blot
        return [children[0], ...children[0].children];
      }

      //  manage db group node
      children[1].position = { x : children[1].x, y : children[1].y };
      delete children[1].x;
      delete children[1].y;

      // offset
      if (blot_size > db_size) {
        children[1].position.y += 50;
      }

      // manage db
      children[1].children.forEach((node) => {

        // assign position
        node.position = { x: node.x, y: node.y };

        // remove un-necessary fields
        delete node.x;
        delete node.y;

        // assign parent
        node.parentId = 'db_group';
        node.extent = 'parent';
      });

      return [children[0], ...children[0].children, children[1], ...children[1].children];
    });
  
  const height = Math.max(db_height, blot_height) * 1.1;

  console.log("Layouted nodes: ", layout_nodes)

  return Promise.resolve({ height, nodes : layout_nodes, edges });
};



// implement without function
function without(array, indexes) {

  var final_array = []
  
  for (var i = 0; i < array.length; ++i) {
    if (!indexes.includes(i)) {
      final_array.push(array[i])
    }
  }

  return final_array;
}

function ReassignFlow({id, flowData, onConnectionChanged, setWindowHeight}, ref) {

  // layout initializer 
  const { updateNodeData } = useReactFlow();

  // nodes and edges state
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [translateExtent, setTranslateExtent] = useState();
  const [viewport, setViewport] = useState();

  const padding = 150;

  const fitViewOptions = useMemo(() => {
    return {
      padding : padding,
    };
  },[]);

  
  // re-evaluate layout
  const set_data_with_layout = useCallback((_nodes, _edges) => {
    
    // determine layout
    getLayoutedElements(_nodes, _edges)
      .then((layouted) => {

        setNodes([...layouted.nodes]);
        setEdges([...layouted.edges]);
        setWindowHeight(layouted.height * 1.5);

        window.requestAnimationFrame(() => {
          
          // TODO: make it work also on non-visible objects
          
          // find db_group and blot_group
          const groups = layouted.nodes.filter((n) => n.type === 'group');

          //console.log(groups);

          const lowestX = Math.min(...groups.map((n) => n.position.x));
          const lowestY = Math.min(...groups.map((n) => n.position.y));

          const highestX = Math.max(...groups.map((n) => n.position.x + n.width));
          const highestY = Math.max(...groups.map((n) => n.position.y + n.height));
          

          setTranslateExtent([
            [lowestX - padding, lowestY - padding],
	          // added 150 to account for the nodes width when calculating the max extent, replace with whatever you want
            [highestX + 150 + padding, highestY + padding * 2],
          ]); 
          
          setViewport({
            x: lowestX + 2 * padding,
            y: padding,
            zoom: 1.,
          });
        });

      });

  }, [setNodes, setEdges, setWindowHeight, fitViewOptions]);

  // convert nodes from flowData
  // determine position (BLOT or DB)
  // edges from flowData
  useEffect(() => {

    // create nodes
    const _nodes = flowData.map(format_node);

    // create edges (if is_connected is true)
    const _edges = flowData.filter(item => item.from === 'BLOT' && item.db_index !== null).map(format_edge);
    
    set_data_with_layout(_nodes, _edges);
  }, [flowData, fitViewOptions]);


  // TODO: deprecated ? 
  const onNodesChange = useCallback((changes) => {
      
    setNodes((nds) =>  { 

      // apply changes
      return applyNodeChanges(changes, nds);
    
    }); // end set nodes

  }, [setNodes]);


  const onEdgesChange = useCallback((changes) => {

      console.log("Edge change: ", changes)
      setEdges((eds) => applyEdgeChanges(changes, eds))

    }, [setEdges]);


  // remove connection after graph modification
  const onConnectionDelete = useCallback((edge) => {
    
    const blotNode = nodes.find(node => node.id === edge.source);
    const dbNode = nodes.find(node => node.id === edge.target);

    var event = {
      blotNode,
      dbNode,
      created : false
    };


    var complete_action = true;

    // fire event
    if (onConnectionChanged) { 
      complete_action = onConnectionChanged(event) 
    }

    // drop and delete edge
    if (complete_action) {

      setEdges((eds) => eds.filter((e) => e.id !== edge.id));

      // modify internal data
      updateNodeData(blotNode.id, { db_index : null });
      updateNodeData(dbNode.id, { blot_index : null });

      // synchronize flowData (silently)
      const blot_data_index = flowData.findIndex(row => row.id == blotNode.id)
      const db_data_index = flowData.findIndex(row => row.id == dbNode.id)

      flowData[blot_data_index].db_index = null;
      flowData[db_data_index].blot_index = null;

    }

  }, [nodes, setEdges, updateNodeData]);

  
  // connection successful
  const onConnect = useCallback((params) => {
    
    const blotNode = nodes.find(node => node.id === params.source);
    const dbNode = nodes.find(node => node.id === params.target);
    const created = (blotNode && dbNode) ? true : false;

    var event = {
      blotNode,
      dbNode,
      created
    };

    // update db_index or blot_index
    if (!created) {
      return;
    }

    console.log("Connection event: ", event)
    
    var complete_action = true;

    // fire event
    if (onConnectionChanged) { 
      complete_action = onConnectionChanged(event) 
    }

    // connect
    if (complete_action) {

      setEdges((eds) => addEdge(params, eds))

      // modify internal data
      updateNodeData(blotNode.id, { db_index : dbNode.id });
      updateNodeData(dbNode.id, { blot_index : blotNode.id });

      // TODO: manage suggestions, different type of edges

      // synchronize flowData (silently)
      const blot_data_index = flowData.findIndex(row => row.id == blotNode.id)
      const db_data_index = flowData.findIndex(row => row.id == dbNode.id)

      flowData[blot_data_index].db_index = dbNode.id;
      flowData[db_data_index].blot_index = blotNode.id;
    }

  }, [nodes, setEdges, updateNodeData]);


  // check whether to connect or not
  const isValidConnection = useCallback((params) => {

    // verify whether source is already connected
    const sourceEdge = edges.find(edge => params.source === edge.source);

    // verify whether target is already connected
    const targetEdge =  edges.find(edge => params.target === edge.target);
    
    // valid if no connection in both directions
    return !sourceEdge && !targetEdge;

  }, [edges]);



  // drop to delete connection
  const edgeReconnectSuccessful = useRef(true);

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge, newConnection) => {

    // Nothing to do, just keep the same
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));


  }, [setEdges]);

  const onReconnectEnd = useCallback((_, edge) => {

    if (!edgeReconnectSuccessful.current) {
      // delete connection
      onConnectionDelete(edge); 
    }

    edgeReconnectSuccessful.current = true;

  }, [onConnectionDelete]);




  const update_node_data = useCallback((transaction) => {

    if (!transaction) {
      console.log("Update verify table: nothing to update")
      return;
    }
    

    for (const tx of transaction) {
    
      // TODO: can be optimized
      const node = nodes.find(node => node.id === tx.id.toString());
   
      // if found
      if (node) {

        const {id, ...updates} = tx;

        console.log("Updating flow: ", updates)

        updateNodeData(id, updates);
      }
    }
    
  }, [nodes, updateNodeData]);


  // TODO: re-evaluate also size and layout
  const remove_pair = useCallback((id) => {

    // find edge by node id
    const target_edge = edges.find(edge => edge.source === id || edge.target === id);

    const source_node_id = target_edge.source;
    const target_node_id = target_edge.target;

    console.log("Removing edge: ", target_edge.id)
    console.log("Removing node: ", source_node_id)
    console.log("Removing node: ", target_node_id)

    // drop and delete edge
    const _edges = edges.filter(e => e.id !== target_edge.id);

    const _nodes = nodes.filter(n => n.id !== source_node_id && n.id !== target_node_id && n.type !== 'group');

    // remove nodes, paired node
    set_data_with_layout(_nodes, _edges);
    
  }, [nodes, set_data_with_layout, edges]);

  
  const node_count = useCallback(() => {
    return Math.max(nodes.length - 2, 0);
  }, [nodes]);


  const get_node = useCallback((id) => {
    return nodes.find(n => n.id === String(id));
  }, [nodes]);


  useImperativeHandle(ref, () => {
    return {
      update_node_data,
      remove_pair,
      node_count,
      set_data_with_layout,
      get_node
    };
  }, [update_node_data, remove_pair, node_count, set_data_with_layout, get_node]);

  const onBeforeDelete = useCallback((event) => {
    
    // reminder: only edges can be deleted
    console.log("Delete pressed")
    
    edges
      .filter(edge => edge.selected === true)
      .forEach(edge => onConnectionDelete(edge)); 
    
    // prevent delection of anything else
    return false;

  }, [edges, onConnectionDelete]);
  
  // TODO LATER: partial selection mode for Edges only
  // TODO LATER: viewport optimization
  
  return ( 
      <ReactFlow
        id={id}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onReconnect={onReconnect}
        onReconnectStart={onReconnectStart}
        onReconnectEnd={onReconnectEnd}
        isValidConnection={isValidConnection}
        panOnDrag={false}
        zoomOnScroll={false}
        panOnScrollMode={'free'}
        selectionOnDrag={true}
        selectNodesOnDrag={false}
        selectionMode={'partial'}
        zoomActivationKeyCode={'Shift'}
        panOnScroll={true}
        translateExtent={translateExtent}
        viewport={viewport}
        onViewportChange={(v) => setViewport(v)} 
        deleteKeyCode={["Backspace", "Delete"]}
        onBeforeDelete={onBeforeDelete}
    />
  );


  /*<Background 
      color='#0A0'
    />
    <Controls />*/
}

export default forwardRef(ReassignFlow);
