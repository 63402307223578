import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, {useState, useEffect} from 'react';

import { DateRangePicker } from 'react-date-range';

import { formatDateUser } from 'modules/datetime';

import {
  Flex,
  Button,
  ButtonGroup,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Popover,
  Text,
  PopoverTrigger,
  useDisclosure,
  Stack
} from '@chakra-ui/react';



export default function DateRangeFilter({dateRange, setDateRange}) {
  
  // initial state
  const defaultRange = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      key: 'selection',
  };
  
  // keep track of selected range
  const [selectedRange, setSelectedRange] = useState(defaultRange);
  const { onOpen, onClose, isOpen } = useDisclosure();


  useEffect(() => {

    const range = { 
      startDate : selectedRange.startDate, 
      endDate : selectedRange.endDate
    }
    setDateRange(range);

  }, [selectedRange, setDateRange]);

  return (
    <Stack>
      <Text fontSize='sm' as='u'>Select trade date range</Text>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnBlur={true}
        >
        <PopoverTrigger>
          <ButtonGroup>
            <Button>{formatDateUser(selectedRange.startDate)}</Button>
            <Button>{formatDateUser(selectedRange.endDate)}</Button>
          </ButtonGroup>
        </PopoverTrigger>

      <PopoverContent w={400}>
        <PopoverBody>
          <DateRangePicker
            ranges={[selectedRange]}
            onChange={(ranges) => setSelectedRange(ranges.selection)}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
    </Stack>
    )
}
