import { PositioningTable } from './positioning_table'
import SideView from './side_view'
import { EditableField, FlatFieldLayout, twodigits_formatter, gbp_formatter } from './aggregation'

import {
  Box,
  SimpleGrid
} from '@chakra-ui/react';

const weight_formatter = {
  format : (variable, source) => {
    if (source.issue_price) {
      return gbp_formatter.format(variable);
    } else {
      const symb = (variable > 0) ? "+" : "";
      return symb + twodigits_formatter.format(variable);
    }
  }
}


function BuyView({buyRowData, aggData, inGrey}) {

  //const table = <PositioningTable rowData={buyRowData} />; 
  const grey_sign = (inGrey) ? ' (diff.)' : '';
  const grey_bg = inGrey ? '#E0E0E0' : '#CCE5FF';

  const aggregators = (
    <>
      <EditableField
        name={'total_buy'}
        source={aggData}
        label={'Total Buy Quantity'} 
        formatter={gbp_formatter}
        layout={FlatFieldLayout}
      />
      <EditableField
        name={'weighted_buy_sum'}
        source={aggData}
        label={'Weighted Buy Price' + grey_sign} 
        formatter={weight_formatter}
        layout={FlatFieldLayout}
        bg={grey_bg}
      />
    </>
  );

  return (
    <SideView 
      title={'Buy'}
      color={'orange'}
      aggregators={aggregators}
    />
  );
}


function SellView({sellRowData, aggData, inGrey}) {

  //const table = <PositioningTable rowData={sellRowData}  />; // onGridReady={refreshTable}
  const grey_sign = (inGrey) ? ' (diff.)' : '';
  const grey_bg = inGrey ? '#E0E0E0' : '#CCE5FF';

  const aggregators = (
    <>
      <EditableField
        name={'total_sell'}
        source={aggData}
        label={'Total Sell Quantity'} 
        formatter={gbp_formatter}
        layout={FlatFieldLayout}
      />
      <EditableField
        name={'weighted_sell_sum'}
        source={aggData}
        label={'Weighted Sell Price' + grey_sign} 
        formatter={weight_formatter}
        layout={FlatFieldLayout}
        bg={grey_bg}
      />
    </>
  );

  return (
    <SideView 
      title={'Sell'}
      color={'#98FB98'} // sell green
      aggregators={aggregators}
    />
  );
}

// Wrapper for Buy / Sell visualization panels
export default function BuySellView({rowData, aggData, inGrey}) {

  // separe in two tables
  const buyRowData = rowData ? rowData.filter((row) => row.trade_side == 'buy') : [];
  const sellRowData = rowData ? rowData.filter((row) => row.trade_side == 'sell') : [];

  return (
    <Box w='100%'>
      <SimpleGrid columns={2} spacing={5}>
        <BuyView buyRowData={buyRowData} aggData={aggData} inGrey={inGrey}/>
        <SellView sellRowData={sellRowData} aggData={aggData} inGrey={inGrey}/>
      </SimpleGrid>
    </Box>
  );
}
