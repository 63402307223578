import { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { BondTable } from './bond_table';
import ActionButtons from './action_buttons'

import { dateFromDB } from 'modules/datetime';


import {
  Box,
  useOutsideClick
} from '@chakra-ui/react';


import Section from 'modules/section'
import { 
  DatabaseServer, 
  load_active_bonds 
} from 'modules/database';

import { useSelector } from "react-redux";

import {
  useBondsSource,
} from 'modules/eventsources';



export default function BondManagerSection() {

  /* Store data */
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState();

  // filters at loading level
  // TODO: manage callback for reload
  //const [loadFilters, setLoadFilters] = useState(defaultLoadFilter)

  const server = useContext(DatabaseServer);

  // get username
  const username = useSelector(state => state.auth.account.username);

  // Enable-disable cancel button when something is selected
  const [selectedRowsNb, setSelectedRowsNb] = useState(0);

  const onSelectionChanged = useCallback(() => {


    const selectedData = gridRef.current.api.getSelectedRows();
    setSelectedRowsNb(selectedData.length);

  }, [setSelectedRowsNb]);

  const gridBox = useRef();
  
  // refresh view function
  const refreshTable = () => {

    // reset data from server
    load_active_bonds(server)
    .then((resp) => {

      if (!resp.ok) {
        return Promise.reject(resp);
      }
      
      return resp.json();
    })
    .then((data) => {
      
     // reset data
     setRowData(data);
   })
   .catch((err) => console.error("Could not load data from database.\n", err));

  }

  const updateTableWith = useCallback((data) => {

    switch (data.action) {

      case 'create':

        // Add to table 
        gridRef.current.api.applyTransaction({
          add: [data.data],
          addIndex: data.data.id,
        });

        break;

      case 'update':

        gridRef.current.api.applyTransaction({
          update: [data.data],
        });

        break;

      case 'delete':

        gridRef.current.api.applyTransaction({ remove: [{id : data.id}] });
        break;

      case 'close':

        // TODO: update table
        break;

      case 'reopen':

        // TODO: update table
        break;
    }
  });

  // Specific Keyboard events
  const handleKeyDown = useCallback((event) => {

    if (!event) {
      return;
    }

    const keyboardEvent = event;
    const key = event.key;

    if (key.length && key === 'Escape' && gridRef.current) {

      gridRef.current.api.deselectAll();
      gridRef.current.api.stopEditing(true);
    }

  }, []);

  useEffect(() => {
    
    // bind event listener
    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }

  }, [handleKeyDown]);




  // refresh all cells at any row change
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells();
    }
  }, [rowData]);


  // setup event connection on mount
  /*useEffect(() => {
    // setup server stream connection
    const eventSource = new EventSource(`${server}transactions/bonds/events/`);

    // catch incoming messages
    eventSource.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      updateTableWith(parsedData);
    }

    return () => {
      eventSource.close();
    };
  });*/

  useBondsSource(updateTableWith);


  return (
    <>
      <Box ref={gridBox} width='100%' minH='300px' height='500px' resize='vertical'  p='4' overflow='auto'>
        <BondTable 
          ref={gridRef} 
          rowData={rowData} 
          setRowData={setRowData} 
          onSelectionChanged={onSelectionChanged} 
          refresh={refreshTable}
          onGridReady={refreshTable}
        />
      </Box>
      <ActionButtons 
        refreshTable={refreshTable} 
        gridRef={gridRef}
        selectedRowsNb={selectedRowsNb}
      />
    </>
   );
}
