import { createContext, useContext } from 'react';

export const Currency = createContext();

export function CurrencyProvider({currency, children}) {

  return (
    <Currency.Provider value={currency}>
      {children}
    </Currency.Provider>
  );
}

// TODO: convertion table

