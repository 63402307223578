import React, {  useCallback, useContext, useMemo, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import {load_all_trades, DatabaseServer} from 'modules/database';
import { Calendar } from 'react-date-range';
import { formatDateUser, convertISOToDate, formatTimeUser } from 'modules/datetime';

import { AGMarketPriceEditor } from 'modules/market_price_input';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
// TODO: possible to choose style, default balham

import 'styles/table.css'

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export function redefineGroupChildren(children, group) {
  // redefine transaction group
  var local_group = Object.assign({
    'cc' : children
  }, group);

  local_group['children'] = local_group['cc'];
  delete local_group['cc'];

  return local_group;
}


/* Basic column components */

export const transaction_id = { 
  headerName: 'ID',  
  field: 'id',
  width: 50,
  filter: null
};


export const trade_status = {  
  headerName: 'Status',    /* Active, Cancel, Pending, Confirmed */
  field: 'trade_status',
  width: 150,
  filter: 'agTextColumnFilter',
  //cellEditor: 'agSelectCellEditor',
  //cellEditorParams: {
  //  values: ['active', 'confirmed'],
  //},
  cellClassRules: {
      // Yellow to active
    'active-yellow': params => params.value === 'Active',
    // Blue to confirmed
    'confirmed-blue': params => params.value === 'Accepted',
    // Red to canceled
    'canceled-red': params => params.value === 'Cancelled',
    // Green to pending
    //'pending-orange' : params => params.value === 'pending'
  },
  //valueFormatter : function(params) {
  //  return params.value.capitalize();
  //},
};

export const bbm_id = {  
  headerName: 'Remote ID',    /* Active, Cancel, Pending, Confirmed */
  field: 'bbm_id',
  width: 150,
  filter: 'agTextColumnFilter',
  cellClassRules: {
    // Blue to confirmed
    'confirmed-blue': params => params.data.trade_status === 'confirmed',
  },
};


export const trade_side = { 
  headerName: 'Side',      /* Buy, Sell*/
  field: 'trade_side',
  width: 150,
  filter: 'agTextColumnFilter',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: {
    values: ['buy', 'sell'],
  },
  cellClassRules: {
      // Green to Sell
    'sell-green': params => params.value === 'sell',
    // Orange to Buy
    'buy-orange': params => params.value === 'buy'
  },
  valueFormatter : function(params) {
    return params.value.capitalize();
  }
};

/* User/Broker column components */

export const user_name = {
  headerName: 'User',       /* Login name */
  field: 'user',
  width: 150,
  filter: 'agTextColumnFilter',
  columnGroupShow: 'open'
};

export const broker_name = {
  headerName: 'Broker',       
  field: 'broker_name',
  width: 150,
	filter: 'agTextColumnFilter',
};

export const broker_alias = {
  headerName: 'Dlr alias',       
  field: 'broker_alias',
  width: 150,
	filter: 'agTextColumnFilter',
};


export const actors_group = {
  headerName: 'Actors', 
  headerClass: 'actors_style',
  children:  [
    user_name, 
    broker_name,
    broker_alias
  ]
};


/* Bond column components */

export const bond_name = {  
  headerName: 'Name',       
  field: 'bond_name',
  width: 150,
  filter: 'agTextColumnFilter',
  cellClassRules: {
    'bond-open': params => params.data.open_position === true,
    'bond-close': params => params.data.open_position === false,
  },
};

export const bond_isin = {
  headerName: 'ISIN',       
  field: 'bond_isin',
  width: 150,
  filter: 'agTextColumnFilter',
};

export const bond_group = {
  headerName: 'Bond', 
  headerClass: 'bond_style',
  children:  [
    bond_name,
    bond_isin
  ]
};

/* Time column components */

export const trade_date = { 
  headerName: 'Trade Date',       
  field: 'trade_datetime',  
  width: 120,
  filter: 'agDateColumnFilter',
  valueFormatter : function(params) {

    if (params.value == null)
      return null;

    return formatDateUser(params.value);
    //return (new Date(params.value).toLocaleDateString());
  }
};

export const trade_time = { 
  headerName: 'Trade time',       
  field: 'trade_datetime',
  width: 120,
  columnGroupShow: 'open',
  valueFormatter : function(params) {

    if (params.value == null)
      return null;

    return formatTimeUser(params.value);
    //return (new Date(params.value).toLocaleDateString());
  }
};

const CalendarEditor = ({ value, onValueChange }) => {

    console.log(value)

    return (
        <Calendar
            date={value}
            onChange={(value) => onValueChange(value)}
        />
    );
}

const DateCell = ({ date, onDateChange }) => {

  console.log("See date:", date)

  const safeConvert = (obj) => {
      return obj ? formatDateUser(obj) : '';
    }

    return (
        <input
            type="date"
            value={safeConvert(date) || ''}
            onChange={({ target: { value } }) => onDateChange(convertISOToDate(value))}
        />
    );
}

export const settlement_date = { 
  headerName: 'Settlement Date',  
  field: 'settlement_datetime', 
  width: 150,
  cellDataType: 'date',
  //cellEditor: 'calendarEditor',
  //filter: 'agDateColumnFilter',
  cellClassRules: {
    'settlement-open': params => params.data.open_position === true,
    'settlement-close': params => params.data.open_position === false,
  },
  valueFormatter : function(params) {

    if (params.value == null)
      return null;

    return formatDateUser(params.value);
  }
};

// TODO: not enough flexible
export const settlement_time = { 
  headerName: 'Settlement time',       
  field: 'settlement_datetime',
  width: 120,
  columnGroupShow: 'open',
  valueFormatter : function(params) {

    if (params.value == null)
      return null;

    return formatTimeUser(params.value);
  }
};

export const time_group = {
  headerName: 'Time', 
  headerClass: 'time_style',
  children:  [
    trade_date,
    trade_time,
    settlement_date,
    //settlement_time
  ]
};

/* Transaction column components */

/*const MarketPriceEditor = ({ value, onChange }) => {

  return (
    <NumericFormat 
      decimalScale={3} 
      fixedDecimalScale
      value={value}
      onChange={onChange}
    />
  );
}*/


export const market_price = { 
  headerName: 'Price',     
  field: 'market_price', 
  columnGroupShow: 'open',
  width: 150,
  filter: 'agNumberColumnFilter',
  cellEditor: 'marketPriceEditor',
  cellClassRules: {
      // Green to Sell
    'market-price-issued': params => params.data.market_price_value,
    // Orange to Buy
    'market-price-grey': params => !params.data.market_price_value
  },
  valueParser: params => Number(params.newValue),
  valueFormatter: function (params) {
    if (params.data.market_price_value) {
      return params.value.toFixed(3);
    } else {

      if (params.value >= 0) {
        return '+' + params.value.toFixed(3);
      } else {
        return params.value.toFixed(3);
      }
    }
  },
  valueGetter: function(params) {
    if (params.data.market_price_value) {
      return params.data.market_price_value;
    } else {
      return params.data.market_price;
    }
  }
};


export const quantity = {
  headerName: 'Quantity (M)',  
  field: 'quantity',
  width: 150,
  filter: 'agNumberColumnFilter',
  valueParser: params => Number(params.newValue),
  valueFormatter: function (params) {return params.value.toFixed(1) ;}
}

export const currency = {
  headerName: 'Currency',  /* Logical formula */    
  field: 'currency',
  width: 150,
  columnGroupShow: 'open',
  filter: 'agTextColumnFilter',
}


export const transaction_group = {
  headerName: 'Transaction', 
  headerClass: 'transaction_style',
  children:  [
    market_price,
    quantity,
    currency
  ]
};

export const security = {
  headerName: 'Security',  /* Logical formula */    
  field: 'security',
  width: 150,
  columnGroupShow: 'open',
  filter: 'agTextColumnFilter',
}

export const comments = {
  headerName: 'Comments',  /* Logical formula */    
  field: 'comments',
  width: 150,
  filter: 'agTextColumnFilter',
}

export const aux_group = {
  headerName: 'Other', 
  headerClass: 'aux_style',
  children:  [
    security,
    comments
  ]
};

/* Default table setup */

export const DefaultTable = React.forwardRef((props, ref) => {

  /* Define grid shape */
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const gridOptions = useMemo(() => ({

    // distinguish styling of rows
    rowClassRules: {
        'grey-row': (params) => { return params.data.bbm_id === null; },

        'confirmed-row': (params) => { return params.data.bbm_id !== null; },
    },

    // other grid options ...
  }), []);

  const [columnDefs, setColumnDefs] = useState([
    transaction_id,
    trade_status,         
    trade_side,             
    actors_group,
    bond_group,          
    time_group,
    transaction_group
  ]);

  const defaultColDef = useMemo(() => {
      return {
        sortable: true,
        width: 150,
        resizable: true,
        filter: true,
        editable : false,
      };
  }, []);


  const components = useMemo(() => ({
      agDateInput: DateCell,
      calendarEditor: CalendarEditor,
      marketPriceEditor : AGMarketPriceEditor
   }), []);

  return (
    <div style={gridStyle} className="ag-theme-balham">
      <AgGridReact
        ref = {ref}
        columnDefs={props.columnDefs ? props.columnDefs : columnDefs}
        defaultColDef={props.defaultColDef ? props.defaultColDef : defaultColDef}
        gridOptions={gridOptions}
        getRowId={(row) => row.data.id}
        components={components}
        reactiveCustomComponents={true}
        colResizeDefault='shift'
        {...props}            
      ></AgGridReact>
    </div>
  )
});
