import { useFilePicker } from 'use-file-picker';

import {
  Button,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  ListItem,
  UnorderedList,
  Text,
  Stack,
  Box,
  Code
} from '@chakra-ui/react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { convertISOToDate } from 'modules/datetime';

import { DatabaseServer, blot_upload, changeDateFormats, fetch_json_check } from 'modules/database';
import { DefaultTable } from 'modules/default_table'

import { useState, useEffect, useRef, useContext } from 'react'


function StatusAlert({isOpen, onClose, status}) {

  const cancelRef = useRef();

  return (
  <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay >
          <AlertDialogContent maxW='' w=''>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {status ? status.title : ''}
            </AlertDialogHeader>

            <AlertDialogBody >
                {status ? status.content : ''}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
  );
}

function Stacktrace({message, errors}) {
  
  const error_stack = errors.map((err) => {

      return Object.entries(err)
        .map(([key, value]) => {
          return `${key} : \n    ${value.join('    \n')}`;
        })
        .join("\n");
  });


  return (
    <>
      <Text>{message}</Text>
      <Stack>
        {error_stack.map((item, index) => <Code key={"error_blot_" +index}>{item}</Code>)}
      </Stack>
    </>
  );
}

export default function BlotLoaderButton({successCallback}) {
  
  // button text
  const BUTTON_TEXT = "Upload BLOT file";
  const [text, setText] = useState(BUTTON_TEXT)

  // status alert, confirm or error
  const [status, setStatus] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure();

  // database connection
  const server = useContext(DatabaseServer);

  // File picker definition
  const { openFilePicker, filesContent, loading, errors } = useFilePicker({
    readAs: 'DataURL',
    accept: ['.xlsx', '.xls', '.csv', '.xlsm', '.odt'],
    readFilesContent: true,
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      // console.log('onFilesRejected', errors);

      const listitems = errors.map((err) => {return <ListItem>{err}</ListItem>}) ;

      setStatus({
          title : 'Error on loading BLOT file',
          content : (<UnorderedList>{listitems}</UnorderedList>)
      });
      onOpen();
    },
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      if (!errors) {
        setText(<Spinner />)

        
        // upload to server
        fetch_json_check(() => blot_upload(server, plainFiles[0]), true)
          .then((success) => {
     
            //data.forEach(changeDateFormats);  

            console.log("BLOT success: ", success.data)
            
            //  callback of set row data
            if (successCallback) {
              successCallback(success.data.data);
            }

            console.warn("Rows not loaded: ", success.data.errors) 
            
            // format and toastify
            for (const err of success.data.errors) {

              const error_stack = Object.entries(err)
                .map(([key, value]) => {
                  return `${key} : \n    ${value.join('    \n')}`;
                })
                .join("\n\n");

              toast.warn(`Could not load blot row due: to \n ${error_stack}`);
            }


          })
          .catch((error) => {

            // error occurred
            console.log("Error loading BLOT", error)

            if (error.status >= 500){

              // server error occurred
              setStatus({
                title : 'BLOT upload: internal server error',
                content : (<Text>{"Check server logs for formatting errors"}</Text>)
              });

            } else {

              console.error("Message: ", error.data.message)
             
              // response rejected: show error
              setStatus({
                title : 'Error on reading BLOT file',
                content : (<Stacktrace message={error.data.message} errors={error.data.errors}/>)
              });

            }


            // open error dialog
            onOpen();

          })
          .finally(() => {

            // reset button to 
            setText(BUTTON_TEXT);
          });
      }
    },
  });

  return (
    <>
      <Button width='200px' colorScheme='blue' onClick={openFilePicker}>
        {text}
      </Button>

      <StatusAlert isOpen={isOpen} onClose={onClose} status={status}/>
    </>
  );
}

