import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dateToDB } from "modules/datetime";

const initialState = { startDate : null, endDate : null };

const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    setDateRange(state,action) {

      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setNow(state) {
      const now = new Date();
      state.startDate = dateToDB(now);
      state.endDate = dateToDB(now);
    }
  },
});

export default dateRangeSlice;
