import React from 'react'

import {
  Flex,
  Image,
  VStack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

import { useDispatch } from "react-redux";
import pageSlice from "store/slices/page";


export default function NavItem({ label, imageurl, onClose, target }) {

  const dispatch = useDispatch();


  function onNavClick() {

    console.log("Storing page: ", target)

    // store on redux
    dispatch(
      pageSlice.actions.setPage({ page : target})
    );

    // TODO: update slice here, setPage action
    //setPage(target);
    onClose();
  }

  return (
    <Flex
      align="center"
      p="4"
      cursor="pointer"
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      borderRadius='10'
      color={useColorModeValue('inherit', 'gray.400')}
      _hover={{
        bg: useColorModeValue('gray.100', 'gray.900'),
        color: useColorModeValue('gray.900', 'gray.200')
      }}
      onClick = {onNavClick}
    >
        <VStack>
          {/*<Image src={imageurl} alt={label} boxSize='40px' />*/}
          <Text fontSize='1xl'>{label}</Text>
        </VStack>
    </Flex>
  );
};
