import { useState, useRef, useMemo, useEffect, useContext, useCallback } from 'react';
import { add_transaction, delete_transaction, cancel_transactions } from 'modules/database'

import { dateFromDB } from 'modules/datetime';

import { ConfirmButton } from './confirm_button';

import BlotLoaderButton from './blot_loader_button';

import ReassignView from './reassign_view';

import MatchLoader from './match_loader';

import {
  Box,
  Stack,
  StackDivider,
  HStack,
  useOutsideClick
} from '@chakra-ui/react';


import Section from 'modules/section'
import { DatabaseServer, load_transactions_range, changeDateFormats } from 'modules/database';

import { useSelector } from "react-redux";


export default function BlotVerifySection() {

  // row data associated with the loaded blot
  const [blotRowData, setBlotRowData] = useState();

  const server = useContext(DatabaseServer);

  return (
    <Stack spacing={4}>
        <HStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={4}
          align='stretch'
        >
          <BlotLoaderButton 
          />
          <MatchLoader onDataLoaded={setBlotRowData} />
        </HStack>
        <ReassignView data={blotRowData}/>
    </Stack>
   );
}
