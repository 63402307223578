import React, { useRef, useState, useEffect, useContext, useCallback, forwardRef }from 'react';
import { useForm } from 'react-hook-form'

import TitledView from 'modules/titled_view'

import { Controller } from 'react-hook-form'

import { 
  DatabaseServer, 
  brokers_create, 
  active_broker_names,
  active_broker_aliases,
  fetch_json_check
} from 'modules/database'

import CreatableSelect from 'react-select/creatable';


import {
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  Divider,
  FocusLock,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Stack,
  StackDivider,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  ScaleFade,
  Spacer,
  Flex,
  Text
} from '@chakra-ui/react';



const components = {
  DropdownIndicator: null,
};

// option creator
const createOption = (label) => ({
  label,
  value: label,
});

/*
 *  Component of Multi Input
 *  To insert more brokers at the time
 */
export const BrokerMultiInput = forwardRef( ({id, control, setError, onSubmit}, ref) => {

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  // Use server
  const server = useContext(DatabaseServer);

  // Bond options
  const [options, setOptions] = useState([]);
  
  // volatile input
  const [inputValue, setInputValue] = useState('');

  // fetch active bonds
  useEffect(() => {

    //console.log("Server change effect")
    
    setIsLoading(true);

    fetch_json_check(() => active_broker_names(server))
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = {'value' : el, 'label' : el};
        });
        
        // set options
        setOptions(data); 
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Broker option error:", err)
      });

  }, [server, setOptions, setIsLoading])
  
    
  // enter and tab set the value
  const handleKeyDown = useCallback((event, prev, setValue) => {


    switch (event.key) {

      case 'Enter':

        if (!inputValue) {
          // submit form
          onSubmit()
          return;
        }

      case 'Tab':

        // check if it doesn't belong to options
        if (! options.find(elem => elem.value === inputValue)) {
          
          const newOpt = createOption(inputValue);

          // set value
          setValue(prev ? [...prev, newOpt] : [newOpt]);
          setInputValue('');

          console.log("Adding broker: ", inputValue)

        } else {
          
          // set error message (react hook form style)
          setError(id, "exists", inputValue + " is already a registered broker");

          console.error("Error: ", inputValue + " is already a registered broker")
        }

        event.preventDefault();
    }
  }, [options, inputValue, setInputValue]);

  return (
      <Controller
        name={id}
        control={control}
        render={
          ({field: { onChange, value, name, ref }}) => (
            <CreatableSelect
              ref={ref}
              components={components}
              isClearable
              isMulti
              menuIsOpen={false}
              value={value}
              onChange={onChange}
              inputValue={inputValue}
              onInputChange={setInputValue}
              name={name}
              onKeyDown={e => handleKeyDown(e, value, onChange)}
              placeholder="Create new broker" 
            />
          )}
        rules={{ required: true }}
      />
  );
});


export const BrokerAliasInput = forwardRef( ({id, control, onSubmit}, ref) => {

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  // Use server
  const server = useContext(DatabaseServer);

  // Bond options
  const [options, setOptions] = useState([]);
  
  // fetch active bonds
  useEffect(() => {

    //console.log("Server change effect")
    
    setIsLoading(true);
    setIsDisabled(true);

    fetch_json_check(() => active_broker_aliases(server))
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = {'value' : el, 'label' : el};
        });
        
        // set options
        setOptions(data); 
        setIsLoading(false);
        setIsDisabled(false);
      })
      .catch((err) => {
        console.error("Broker option error:", err)
      });

  }, [server, setOptions, setIsLoading])
  
  return (
      <Controller
        ref={ref}
        name={id}
        control={control}
        render={
          ({field: { onChange, value, name, ref }}) => (
            <CreatableSelect
              ref={ref}
              isClearable
              isDisabled={isDisabled}
              isLoading={isLoading}
              value={value}
              onChange={onChange}
              name={name}
              options={options}
              placeholder="Assign alias" 
            />
          )}
      />
  );
});


const BrokerInput = forwardRef(({control, errors, setError, onSubmit}, ref) => {

  return (
    <FormControl isInvalid={errors.broker_name}>
      <FormLabel>Register new brokers (full name)</FormLabel>
      <BrokerMultiInput 
        ref={ref} 
        id={'broker_name'}
        control={control}
        onSubmit={onSubmit}
        setError={setError}
      />
      <FormErrorMessage>
        {errors.broker_name && errors.broker_name}
      </FormErrorMessage>
    </FormControl>
  )
});


const AliasInput = forwardRef(({control, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.broker_name}>
      <FormLabel>Assign (or create) alias</FormLabel>
      <BrokerAliasInput 
        ref={ref} 
        id={'broker_alias'}
        control={control}
      />
      <FormErrorMessage>
        {errors.broker_alias && errors.broker_alias}
      </FormErrorMessage>
    </FormControl>
  )
});


/*
 *  Actual form
 */
export default function BrokerCreateForm() {

  /* Fields */

  const { onOpen, onClose, isOpen } = useDisclosure();
  const inputRef = useRef(null);
  
  /* Form */
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  // handle cancelling
  function onCancel() {
     reset();
     onClose();
  }

  const server = useContext(DatabaseServer);

  function onSubmit(values) {
    
    // for each new possible broker in the input
    const brokers = values['broker_name'].map(e => e.value)

    const alias = values['broker_alias'].value

    // fetch server 
    brokers_create(server, brokers, alias).
      then((responses) => {
        if (!responses.ok) {
          return Promise.reject(responses);
        }
        
        return responses.data.map(res => res.json());
      }).
      then((data) => {
    
        console.log("Broker created: ", data);
    
        reset();
        onClose();
      }).
      catch((err) => {
        console.error("Error on submitting form for broker creation: ", err)
      });
  }

  const FormButtons = () => {
    return (
        <ButtonGroup display='flex' justifyContent='flex-end'>
            <Button variant='outline' onClick={onCancel} >
                Cancel
            </Button>
            <Button colorScheme='teal' onClick={handleSubmit(onSubmit)}>
                Submit
            </Button>
        </ButtonGroup>
      );
  }




  return (
    <Popover
        isOpen={isOpen}
        initialFocusRef={inputRef}
        onOpen={onOpen}
        onClose={onCancel}
        placement='top-start'
        closeOnBlur={false}
        >
        <PopoverTrigger>
          <Button colorScheme='purple'>New broker</Button>
        </PopoverTrigger>

        <PopoverContent w={400}>
        <FocusLock returnFocus persistentFocus={false}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverHeader> Insert new brokers </PopoverHeader>
            <PopoverBody>
              <Stack
                divider={<StackDivider borderColor='gray.200' />}
                spacing={4}
                align='stretch'
              >
                <BrokerInput 
                  ref={inputRef} 
                  control={control}
                  onSubmit={handleSubmit(onSubmit)}
                  setError={setError}
                  errors={errors}
                />
               
                <AliasInput
                  control={control}
                  errors={errors}
                />
              </Stack>
            </PopoverBody>

            <PopoverFooter
                border='0'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                pb={4}
              >
                <FormButtons />
            </PopoverFooter>
          </form>

        </FocusLock>
        </PopoverContent>

      </Popover>
    );

}

