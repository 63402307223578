import { 
  NumberInput, 
  NumberInputField,
  IconButton,
  Stack,
  HStack,
  Flex,
  Text } from '@chakra-ui/react'

import { CheckIcon } from '@chakra-ui/icons';

import { useState, useRef, useEffect, useContext, useCallback} from 'react';

import { useSelector, useDispatch } from "react-redux";

import { DatabaseServer, bond_update, bond_info } from 'modules/database';


export default function IssuePriceSelector({setIssuePrice}) {

  // get current bond from slices
  const bond = useSelector(state => state.loaders.bond);

  const dispatch = useDispatch();

  // track floating state
  const [value, setValue] = useState(null);

  // disable
  const [disabled, setDisabled] = useState(false);

  // in grey state
  const [inGrey, setInGrey] = useState(true);

  // Use server
  const server = useContext(DatabaseServer);

  // confirmed selection, upload to server
  const selectIssuePrice = useCallback((result) => {
    
    if (!bond) {
      // nothing to do
      return;
    }
    
    // set state
    setIssuePrice(result);

  }, [bond]);


  useEffect(() => {

    if (!bond) {
      // nothing to do
      return;
    }

    // get bond info on component mount
    bond_info(server, bond)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject();
        }

        return res.json();
      })
      .then((data) => {
        
        setValue(data.issue_price);
        setInGrey(data.issue_price === null);

        // assign stored mkt bid
        if (data.issue_price) {
          setDisabled(true);
        }

      }).catch((err) => {
        
        console.error("Issue price impossible to get for bond: ", bond);
      });

  }, [server, bond]);


  function confirm() {

    if ((inGrey || value) && value >= 0) {
      selectIssuePrice(value ? value : null);
      //console.log("Market bid set to: ", marketBid)
    }
  }

  function handleKeyPress(e) {
    
     // This is perfectly safe in react, it correctly detect the keys
    if(e.key == 'Enter'){
        confirm()
      }
  }

  function focusLost(e) {
    confirm()
  }

  const title = 'Issued Price' + ((inGrey) ? ' (speculative)' : '');

  return (
    <Stack>
      <Text fontSize='sm' as='u'>{title}</Text>
        <NumberInput 
          min={0}
          value={value ? value : ''} 
          disabled={disabled}
          onChange={(v) => setValue(v)}
          onKeyDown={handleKeyPress}
          onBlur={focusLost}>
          <NumberInputField />
        </NumberInput>
    </Stack>
  );
}
