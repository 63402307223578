import { 
  Box, 
  Flex,
  VStack, 
  Text,
  Divider
} from '@chakra-ui/react';


export default function BalanceField({name, isin, issue_price, currency, expiration, qty_balance, onClick}) {

  // lungo: quantity balance > 0
  // corto: quantity balance < 0
  // pari: quantity balance = 0
  function get_color() {
    
    if (qty_balance > 0)
      return '#1CA517';
    else if (qty_balance < 0)
      return '#D41D2A';
    else
      return '#FFFFFF';
  }

  const color = get_color(); 

  // color with gradient
  const gradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', color)


  return (
    <Flex 
      minW='200px' 
      p='4' 
      m='1' 
      borderWidth='1px' 
      borderRadius='lg' 
      boxShadow='dark-lg' 
      justify='center' 
      bgGradient={gradient}
      _hover={ { boxShadow :'base', filter : "brightness(60%)"} }
      _active={ { boxShadow :'base', filter : "hue-rotate(-90deg)" } }
      onClick={onClick ? (event) => onClick(event, name, isin) : undefined}
    >
      <VStack>
        <Text pt='1' pb='1' fontSize='2xl'> {name} </Text>
        <Box align="right">
          <Text fontSize='sm'> {isin} </Text>
        </Box>
        <Divider orientation='horizontal' w='200px'/>
        <Text> {expiration} Y </Text>
        <Text fontSize='2xl' fontWeight='bold'> {qty_balance} </Text>
        <Text > {issue_price} {currency} </Text>
      </VStack>
    </Flex>
  );
}
