import React, { 
  useState, 
  useRef, 
  useEffect, 
  useContext,
  useCallback
} from 'react';

import { 
  DatabaseServer, 
  active_bonds,
  fetch_json_check
} from 'modules/database';

import {
  Box,
} from '@chakra-ui/react';

import TitledView from 'modules/titled_view';

import { useSelector, useDispatch } from "react-redux";

import loadersSlice from "../store/slices/loaders";

import Select from 'react-select';

import {
  useBondsSource,
} from 'modules/eventsources';



// tool
const createOption = (val) => { return {'value' : val, 'label' : val}; }


export default function BondSelector({bond, onBondChange}) {

  // Use server
  const server = useContext(DatabaseServer);

  // Bond options
  const [bondOptions, setBondOptions] = useState([]);

  // Selector
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const selectRef = useRef();

  // fetch active bonds
  const reloadOptions = useCallback(() => {

    console.log("Fetching bond options: ", server)
    

    fetch_json_check(() => active_bonds(server))
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = {'value' : el, 'label' : el};
        });

        
        // set options
        setBondOptions(data); 

        console.log("Got bond options: ", data)
        
      })
      .catch((err) => {
        console.error("Bond option error:", err)
      });

  }, [server, setBondOptions])


  useEffect(() => {
    reloadOptions();
  }, [server]);


  useEffect(() => {

    if (bondOptions.length === 0) {

      // disable select
      setIsLoading(true);
      setIsDisabled(true);

    } else {
      
      // now options are available

      // enable select
      setIsLoading(false);
      setIsDisabled(false);

      //selectRef.current.selectOption(createOption(bond));

    }

  }, [bondOptions]);


  const updateTableWithBond = useCallback((event) => {
    switch (event.action) {
      case 'update':

        // name changed
        if ('name' in event.changed) {

          // reload options
          reloadOptions();
          
          // if the involved bond name is the one selected
          if (event.data.name === bond) {
            onBondChange(event.data.name);
          }
        }

      case 'delete':

        // reload options
        reloadOptions();

        // reset if selected
        if (event.name === bond) {
          onBondChange(null);
        }

        break;
    }
    
  }, [server, reloadOptions]);



  // setup event connection on mount
  /*useEffect(() => {
    
    // setup server stream connection
    let eventSourceBonds = new EventSource(`${server}transactions/bonds/events/`);

    // catch incoming messages
    eventSourceBonds.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      updateTableWithBond(parsedData);
    }

    const cleanup = () => {
      eventSourceBonds.close();
    };

    // ensure cleanup on refresh page
    window.addEventListener('beforeunload', cleanup);

    return cleanup;

  }, [server]);*/


  useBondsSource(updateTableWithBond)


  return (
    <TitledView title='Bond name'>
      <Box minW='200px'>
      <Select
        ref ={selectRef}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={false}
        isRtl={false}
        isSearchable
        name={"Bond name"}
        options={bondOptions}
        value={bondOptions ? createOption(bond) : null}
        onChange={opt => onBondChange(opt ? opt.value : null)}
      />
      </Box>
    </TitledView>
  );
}
