import { 
  Flex, 
  Stack, 
  Text } from '@chakra-ui/react'

export default function TitledView({title, children}) {

  return (
    <Flex>
      <Stack>
        <Text fontSize='sm' as='u'>{title}</Text>
        <Flex>
          {children}
        </Flex>
      </Stack>
    </Flex>
  );
}

