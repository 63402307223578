import React, { useState, useEffect } from 'react';

import {
  Box,
  Flex,
  VStack,
  HStack,
  StackDivider,
  Text,
  StatGroup
} from '@chakra-ui/react';


function SideTitle({title, color}) {

  // color
  const gradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', color)
  console.log(gradient)
  return (
    <Flex bgGradient={gradient} borderRadius='lg'>
      <Text pt='3' pr='2' fontSize='2xl'>{title} </Text>
    </Flex>
  );
}

export default function SideView({title, color, aggregators, children}) {

  //<Box w='100%' height= '300px' minH='300px' resize='vertical'  p='4' overflow='auto'>
  //</Box>
  return (
    <Flex borderWidth='1px' borderRadius='lg' p='4'>
      <Box w='100%'>
      <VStack 
        divider={<StackDivider borderColor='{gray.200' />}
        spacing={4}
        align='stretch'
      >
        <SideTitle title={title} color={color}/>
        {children}
        <StatGroup>
          <VStack w='100%'>
            {aggregators}            
          </VStack> 
        </StatGroup>
      </VStack>
    </Box>
    </Flex>
  );
}
