
/*
    Left is group 0
    Right is group 1 (graph optional)
  
    Options:
      groupSpacing: horizontal spacing between groups
      nodeSpacing: vertical spacing between nodes
      padding: offset inside the group container (resize it)
      offset: offset position of the entire graph
      nodeWidth: width of a node
      nodeHeight: height of a node
    
    Assumption: this layout assumes the node width and height are known
*/

// same as elk
export async function layout(graph) {
  
  // check 
  if (!graph.children) {
    return Promise.reject("Graph has no children field.", graph);
  }

  if (graph.children.length < 1) {
    return Promise.reject("You must at least have one group.", graph);
  }

  // options (TODO and defaults)
  const {
      groupSpacing,
      nodeSpacing,
      padding,
      offset,
      nodeWidth,
      nodeHeight
  } = graph.layoutOptions;


  const node_placement = (node, index) => {

    // relative placement
    node.x = padding;
    node.y = padding + index * (nodeHeight + nodeSpacing);

    // width and height
    node.width = nodeWidth;
    node.height = nodeHeight;
  };
  
  // modify left group (origin)
  graph.children[0].x = offset.x;
  graph.children[0].y = offset.y;

  graph.children[0].width = 2 * padding + nodeWidth;
  graph.children[0].height = 2 * padding + (nodeHeight + nodeSpacing) * graph.children[0].children.length - nodeSpacing;

  graph.children[0].children.forEach(node_placement);
  
  // modify right group (displaced in width, same height)
  if (graph.children.length > 1) {

    graph.children[1].x = offset.x + 2 * padding + nodeWidth + groupSpacing;
    graph.children[1].y = offset.y;

  graph.children[1].width = 2 * padding + nodeWidth;
  graph.children[1].height = 2 * padding + (nodeHeight + nodeSpacing) * graph.children[1].children.length - nodeSpacing;
    
    graph.children[1].children.forEach(node_placement);
  }

  return Promise.resolve(graph);
}
