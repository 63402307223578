import { 
  useState, 
  useEffect, 
  useContext, 
  useCallback 
} from 'react';

import { Flex, Box } from '@chakra-ui/react'

import 'styles/index.css';

import Section from 'modules/section'
import DashboardSection from 'dashboard/dashboard_section'
import LocalTradesSection from 'local_trades/local_trades_section'
import PositioningSection from 'positioning/positioning_section'
import BondManagerSection from 'bond_manager/bond_manager_section'
import BlotVerifySection from 'blot_verify/blot_verify_section'
import HistorySection from 'history/history_section'

import { DatabaseServer } from "modules/database";

import { useSelector } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  useTransactionsSource,
  useBondsSource,
  useBrokersSource,
  useBbmSyncSource 
} from 'modules/eventsources';

import {
  TransactionChecksum,
  BondChecksum,
  BrokerChecksum
}from 'modules/checksums';


export default function AppPage() {

  // import initial state from redux store
  const pageStored = useSelector(state => state.page.page);

  console.log("Page to load: ", pageStored)

  // Page selector
  //const [page, setPage] = useState(0);

  function SelectPage() {

    switch (pageStored) {
    case 0:
      return <DashboardSection/>;
    case 1:
      return <PositioningSection/>;
    case 2:
        return <LocalTradesSection />;
    case 3:
        return <BondManagerSection />;
    case 4:
        return <BlotVerifySection />;
    default:
      return;
    }
  }
  
  let title;
  switch (pageStored) {
    case 0:
      title = 'Open positions';
      break;
    case 1:
      title = 'Position details';
      break;
    case 2:
      title = "Transactions manager";
      break;
    case 3:
      title = "Bond manager";
      break;
    case 4:
      title = "Blot matching";
      break;
    default:
      break;
  }

  const notify_transactions = useCallback((event) => {
    

    switch (event.action) {
      case 'create':

        console.log("Data", event.data)

        toast.info(
          <TransactionChecksum
            title={`A new transaction was created`}
            sum={event.data}
            />
        );

        break;

      case 'update':

        //toast.info(`Transaction ${event.data.id} was updated \n\n ${checksum(event.data)}`);
        toast.info(
          <TransactionChecksum
            title={`A transaction was updated`}
            sum={event.data}
            />
        );

        break;

      case 'delete':

        toast.info(
          <TransactionChecksum
            title={`A transaction was deleted`}
            sum={event.data}
            />
        );


        //toast.info(`Transaction ${event.id} was deleted \n\n ${checksum(event.data)}`);

        break;
    }

  });

  const notify_bonds = useCallback((event) => {

    switch (event.action) {

      case 'create':

        toast.info(
          <BondChecksum
            action='registered'
            sum={event.data}
          />
        );

        break;

      case 'update':

        toast.info(
          <BondChecksum
            action='updated'
            sum={event.data}
          />
        );
        break;

      case 'delete':

        toast.info(
          <BondChecksum
            action='deleted'
            sum={event.data}
          />
        );

        break;

      case 'close':

        toast.info(
          <BondChecksum
            action='closed'
            sum={event.data}
          />
        );


        break;

      case 'reopen':

        toast.info(
          <BondChecksum
            action='re-opened'
            sum={event.data}
          />
        );

        break;
    }

  });

  const notify_brokers = useCallback((event) => {

    switch (event.action) {
      case 'create':

        toast.info(
          <BrokerChecksum
            action='registered'
            sum={event.data}
          />
        );

        break;

      case 'update':

        toast.info(
          <BrokerChecksum
            action='updated'
            sum={event.data}
          />
        );

        break;

      case 'delete':

        toast.info(
          <BrokerChecksum
            action='deleted'
            sum={event.data}
          />
        );

        break;
    }

  });

  const notify_bbmsync = useCallback((event) => {

    switch (event.action) {

      case 'blot_upload':

        // TODO: notify: more indicative information (color = buy/sell, user, bond name/isin)
        toast.info(`BLOT ${event.filename} was uploaded. \n Rows: ${event.data.length}`);

        break;
    }

  });
  
  useTransactionsSource(notify_transactions);
  useBondsSource(notify_bonds);
  useBrokersSource(notify_brokers);
  useBbmSyncSource(notify_bbmsync);

    
  return (
      <Flex justify="space-between">
       
        {/* Render application panel*/}
        <Box w="100%" as="section"  p = "30px" py="10px">
          <Section title={title}>
            <SelectPage/>
            <ToastContainer 
              position="bottom-right"
              closeOnClick
              draggable 
            />
          </Section>
        </Box>
       
      </Flex>
  );
}
