import React from 'react';
import LocalTradesForm from './local_trades_form';
import BondMatchForm from './bond_match_form';
import BondClose from './bond_close';

import {
  Button,
  ButtonGroup,
  StackDivider,
  HStack
} from '@chakra-ui/react';

import CancelButton from './cancel_button'
import BlotButton from './blot_button'
import BondCreateForm from 'modules/bond_create_form'
import BrokerCreateForm from './broker_create_form'

export default function SubmitButton({addTransaction, cancelTransactions, refreshTable, cancelButtonRef}) {

  const addBuyTransaction = function(inputData) {
      console.log("Submitting Buy transaction")
      return addTransaction(inputData, 'buy');
  } 

  const addSellTransaction = function(inputData) {
      console.log("Submitting Sell transaction")
      return addTransaction(inputData, 'sell');
  } 

  //<BlotButton refreshTable={refreshTable} colorScheme='blue'/>
  //
  return (
    <HStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
    >
    <ButtonGroup>

      {/*<Button colorScheme='blue'>Submit selection</Button>*/}
      <LocalTradesForm label='Buy' colorScheme='orange' addTransaction={addBuyTransaction}/>
      <LocalTradesForm label='Sell' colorScheme='green' addTransaction={addSellTransaction}/>
      <CancelButton 
        ref={cancelButtonRef}
        colorScheme='red'  
        onConfirm={cancelTransactions}
      >Cancel
      </CancelButton>

    </ButtonGroup>

    <ButtonGroup>

      <BondCreateForm />
      <BrokerCreateForm />
      <BondMatchForm submitCallback={refreshTable} />
      <BondClose />
      
    </ButtonGroup>
  </HStack>
  );
}
