import { 
  useState, 
  useRef, 
  useMemo, 
  memo,
  useEffect, 
  useContext, 
  useCallback 
} from 'react';

import  { 
    ReactFlowProvider
} from '@xyflow/react';

import TitledView from 'modules/titled_view';

import {
  Box,
  Flex,
  Stack,
  HStack,
  StackDivider,
  Text,
  StatGroup,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container,
  Checkbox,
  Divider,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';

import { dateToDB } from 'modules/datetime'

import VerifyTable from './verify_table'
import ReassignFlow from './reassign_flow'

import { 
  connect_records, 
  unconnect_records, 
  DatabaseServer, 
  fetch_json_check 
} from 'modules/database';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const blot_color = '#87CEEB';
const db_color = '#FFD700';

function StructureTitle() {

  // color
  const blot_gradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', blot_color)
  const db_gradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', db_color)

  return (
    <Flex width='100%'  borderRadius='lg'>
      <Flex width='50%' bgGradient={blot_gradient}>
        <Text pt='4' pr='3' fontSize='3xl'> BLOT </Text>
      </Flex>
      <Flex width='50%' bgGradient={db_gradient}>
        <Text pt='4' pr='3' fontSize='3xl'> Database </Text>
      </Flex>
    </Flex>
  );
}



function StructureView({children}) {

  return (
    <Box w='100%' minH='300px'  p='4' overflow='visible'>
      {children} 
    </Box>
  );
}


// graphics frame
function ReassignBondFrame({isin, name, issue_price, flow, height}) {

  // TODO: close button
  // TODO: stats next to issue price

  return (
    <Box w='100%' borderWidth='1px' borderRadius='lg' p='4'>
    <Stack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={2}
    >
      <HStack
        divider={<StackDivider borderColor='gray.200' />}
        spacing={6}
        align='stretch'
      >
        <TitledView title='Bond name'> 
          <Text fontSize='lg' pl={2} as='b'> {name} </Text>
        </TitledView>
        <TitledView title='Bond isin'> 
          <Text fontSize='lg' pl={2} as='b'> {isin} </Text>
        </TitledView>
        <TitledView title='Bond issue price'> 
          <NumberInput
            min={0}
            defaultValue={issue_price} 
            disabled={true}
            placeholder={"No issue price specified"}
          >
            <NumberInputField />
          </NumberInput>
        </TitledView>
      </HStack>
      <div style={{ height : (height.toString() + 'px'), minHeight : '400px', resize : 'vertical', overflow : 'auto' }} >
              {flow}
      </div>
    </Stack>
  </Box>

  );

  // 
   // <Tabs>
   //     <TabList>
   //       <Tab>{'Assign'}</Tab>
  //        <Tab>{'Matching quality'}</Tab>
  //      </TabList>
  //      <TabPanels>
  //        <TabPanel>
  //          
  //        </TabPanel>
  //        <TabPanel>
   //         Matching stats coming soon
   //       </TabPanel>
  //      </TabPanels>
  // </Tabs>

}

/*
 *  This flow is a bipartite graph having:
 *    - BLOT on the right hand side
 *    - DB or database on the left hand side
 *
 *  Edges can be of two types:
 *    - Connections: strong, flagged by the "is_connected" field, once established they can be changed only by users. 
 *    - Suggestions: weak, those are evaluated automatically
 *
 *  A connection is characterized by flowData having:
 *    - is_connected = true
 *    - blot_index not null
 *    - db_index not null
 *
 *  A suggestion is characterized by flowData having:
 *    - is_connected = false
 *    - blot_index not null
 *    - db_index not null
 *    - score is not 0
 */

// Viewport for a single bond
export function ReassignBond({id, name, isin, issue_price, bond_data, job_done}) {
  
  // flow
  const flowRef = useRef();

  // data controller
  const [flowData, setFlowData] = useState(bond_data);
  
  // graphics
  const [windowHeight, setWindowHeight] = useState(0);
  

  const onConnectionChanged = useCallback((event) => {

    // A connection can be created or deleted, no other possibilities
    if (event.created) {

      connect_records(server, event.blotNode.id, event.dbNode.id)
        .then((res) => {
          if (!res.ok) {
            return Promise.reject(res);
          }

          return Promise.resolve(res);

        }).then(() => {

          console.log(`Connection created between ${event.blotNode.id} and ${event.dbNode.id}`);

        }).catch((err) => {

          console.error(`Couldn't connect ${event.blotNode.id} and ${event.dbNode.id}`);
          return false;

        });


    } else {
    
      unconnect_records(server, event.blotNode.id, event.dbNode.id)
        .then((res) => {
          if (!res.ok) {
            return Promise.reject(res);
          }

          return Promise.resolve(res);

        }).then(() => {

          console.log(`Connection deleted between ${event.blotNode.id} and ${event.dbNode.id}`)

        }).catch((err) => {

          console.error(`Couldn't unconnect ${event.blotNode.id} and ${event.dbNode.id}`);
          return false;

        });
    }
    
    // return true = allow action to be completed
    return true;

  }, []);

  const server = useContext(DatabaseServer);

  const onClose = useCallback(() => {
    job_done();
  }, [server]);

    
  // TODO: accordion heading NAME, ISIN, ISSUE PRICE (input) over-titles
  return (
    <ReassignBondFrame
      isin={isin}
      name={name}
      issue_price={issue_price}
      height={windowHeight}
      flow={<ReassignFlow
        ref={flowRef}
        id={id}
        flowData={flowData} 
        onConnectionChanged={onConnectionChanged}
        setWindowHeight={setWindowHeight}
      />}
    />
  );
}

export default function ReassignView({data}) {

  // keep track of the state of what is done what is not
  //const [jobIsDone, setJobIsDone] = useState({});

  const [bondComponents, setBondComponents] = useState([]);

  const ReassignMemo = memo(function ReassignMemo({isin, name, issue_price, bond_data}) {

    // do not show anymore when job is done
    const [show, setShow] = useState(true);

    //console.log("Rendering reassign with isin: ", isin)
    console.log("Bond data: ", bond_data)

    return (
        show &&
        <ReactFlowProvider>
          <ReassignBond 
            id={'flow_' + isin}
            isin={isin} 
            name={name}
            issue_price={issue_price}
            bond_data={bond_data}
            job_done={() => setShow(false)}
          />
        </ReactFlowProvider>
      );
  });

  // for each bond create a ReassignBond
  useEffect(() => {

    if (!data) {
      return;
    }

    console.log("Data loaded: ", data)
    
    // notify errors
    data.filter(bond => bond.error).forEach((bond) => {
      // TODO: toast notify
      toast.error(`Error occurred while loading bond ${bond.name}/${bond.isin}: ${bond.error}`);
      console.error(bond.stacktrace)
    });
    
    setBondComponents(
      data.filter(bond => bond.graph).map((bond) => {
        return <ReassignMemo 
          key={bond.name + '_' + bond.isin}
          isin={bond.isin}
          name={bond.name}
          issue_price={bond.issue_price}
          bond_data={bond.graph}
        />
      })
    );
    
  }, [data]);

  // TODO: debug
  return (
    <StructureView>
     <Stack spacing={4}>
       {bondComponents}
     </Stack>
    </StructureView>
  );
}
