import { createContext, useContext } from 'react';

import { DatabaseProvider } from 'modules/database'
import { PythonProvider } from 'modules/pythonContext'
import { ChakraProvider } from '@chakra-ui/react'
import { CurrencyProvider } from 'modules/currency'
import { EventSourceProvider } from 'modules/eventsources'


export default function QuickTraderProvider({hostname, currency, pylibs, children}) {

  return (
    <ChakraProvider>
      <DatabaseProvider hostname={hostname ? hostname : process.env.REACT_APP_API_URL}>
          <EventSourceProvider >
          <CurrencyProvider currency={currency ? currency : 'EUR'}>
            {children}
          </CurrencyProvider>
        </EventSourceProvider>
      </DatabaseProvider> 
    </ChakraProvider>
  );
}
  
//<PythonProvider libs={pylibs ? pylibs : ["numpy", "pandas"]}>
//</PythonProvider>

