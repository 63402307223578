import DateRangeFilter from 'modules/date_range_filter'

import React, {useState, useEffect} from 'react';

import { useDispatch } from "react-redux";
import dateRangeSlice from "store/slices/dateRange";


import {
  Flex,
  HStack,
} from '@chakra-ui/react';

import { useSelector } from "react-redux";
import { dateFromDB, dateToDB } from "modules/datetime";

export default function LoadFilters() {


  // import initial state from redux store
  const initialDateRange = useSelector(state => state.dateRange);

  // initial state is defined?
  const isDateDefined = initialDateRange.startDate && initialDateRange.endDate;

  // control state
  const [dateRange, setDateRange] = useState(isDateDefined ? 
      {startDate : dateFromDB(initialDateRange.startDate), endDate : dateFromDB(initialDateRange.endDate)}
    : {startDate : new Date(), endDate : new Date()});

  const dispatch = useDispatch();

  
  // bind filter change to load state
  useEffect(() => {

    const dateDefined = dateRange.startDate && dateRange.endDate;

    if (dateDefined) {

      // convert in timestamps
      const store = {
        startDate : dateToDB(dateRange.startDate),
        endDate : dateToDB(dateRange.endDate)
      };

      console.log("Storing: ", store)

      // store on redux
      dispatch(
        dateRangeSlice.actions.setDateRange(store)
      );
    }

    //console.log("Date range: ", dateRange)
  }, [dateRange]);

  return (
    <Flex>
      <HStack>
        <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange}/> 
      </HStack>
    </Flex>
  );
}
