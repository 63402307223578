import React, {  useCallback, useContext, useMemo, useState, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { 
  load_all_active_bonds, 
  DatabaseServer, 
  bond_update, 
  bond_update_by_isin, 
  currencies, 
  fetch_json_check 
} from 'modules/database';

import { Calendar } from 'react-date-range';
import { formatDateUser, convertISOToDate, formatTimeUser } from 'modules/datetime';


import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
// TODO: possible to choose style, default balham

import 'styles/table.css'


/* Identity components */


const name_field = {  
  headerName: 'Name',    /* Active, Cancel, Pending, Confirmed */
  field: 'name',
  width: 150,
  filter: 'agTextColumnFilter',
  editable: true
};

const isin_field = { 
  headerName: 'ISIN',      /* Buy, Sell*/
  field: 'isin',
  width: 150,
  filter: 'agTextColumnFilter',
  editable: true
};

// define group
const identity_group = {
  headerName: 'Identity', 
  headerClass: 'bond_identity',
  children:  [
    name_field, 
    isin_field
  ]
};


/* Tenor info */

const tenor_type_field = {
  headerName: 'Tenor',
  field: 'tenor',
  width: 150,
  filter: 'agTextColumnFilter',
  editable: true,
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: {
    values: ['PERP', 'EXP'],
  },
}

const expiration_field = {
  headerName: 'Expiration',
  field: 'expiration',
  width: 150,
  editable: (params) => params.data.tenor === 'EXP',
  filter: 'agNumberColumnFilter',
  valueParser: params => params.newValue ? Number(params.newValue) : null,
  valueFormatter: (params) => (params.data.tenor === 'EXP' && params.value) ? params.value + ' Y' : ''
}

// define group
const tenor_group = {
  headerName: 'Tenor', 
  headerClass: 'bond_tenor',
  children:  [
    tenor_type_field, 
    expiration_field
  ]
};


/* Rate info */

const rate_type_field = {
  headerName: 'Rate',
  field: 'rate_type',
  width: 150,
  editable: true,
  filter: 'agTextColumnFilter',
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: {
    values: ['FIX', 'FRN'],
  },
}

const yield_value_field = {
  headerName: 'Yield',
  field: 'yield_value',
  width: 150,
  editable: true,
  filter: 'agNumberColumnFilter',
  cellEditor: 'agNumberCellEditor',
  valueParser: params => params.newValue ? Number(params.newValue) : null,
}

const yield_period_field = {
  headerName: 'Period',
  field: 'yield_period',
  width: 150,
  editable: (params) => params.data.rate_type === 'FRN',
  filter: 'agNumberColumnFilter',
  cellEditor: 'agNumberCellEditor',
  valueFormatter: (params) => (params.data.rate_type === 'FRN' && params.value) ? params.value + ' m' : '',
  valueParser: params => params.newValue ? Number(params.newValue) : null,
}

// define group
const rate_group = {
  headerName: 'Rate / Yield', 
  headerClass: 'bond_yield',
  children:  [
    rate_type_field, 
    yield_value_field,
    yield_period_field
  ]
};


/* Price info */

const issue_price_field = {
  headerName: 'Issue price',
  field: 'issue_price',
  width: 150,
  editable: true,
  filter: 'agNumberColumnFilter',
  cellEditor: 'agNumberCellEditor',
  valueParser: params => params.newValue ? Number(params.newValue) : null,
}

const market_bid_field = {
  headerName: 'Market BID',
  field: 'market_bid',
  width: 150,
  editable: (params) => params.data.issue_price,
  filter: 'agNumberColumnFilter',
  cellEditor: 'agNumberCellEditor',
  valueParser: params => params.newValue ? Number(params.newValue) : null,
}

const currency_field = (values) => {
  return {
    headerName: 'Currency',
    field: 'currency',
    width: 150,
    editable: true,
    filter: 'agTextColumnFilter',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
        values : values 
    },
    editable : true
  };
};

// define group
const price_group = (currencies) => {

  return {
    headerName: 'Price', 
    headerClass: 'bond_price',
    children:  [
      issue_price_field, 
      currency_field(currencies),
      market_bid_field
    ]
  };
};

/* Default table setup */

export const BondTable = React.forwardRef((props, ref) => {

  /* Use database context */
  const server = useContext(DatabaseServer);

  /* Define grid shape */
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  
  // currency options state
  const [currenciesOptions, setCurrencyOptions] = useState(['EUR', 'GBP', 'USD', 'CHF']);

  // load currencies
  useEffect(() => {

    currencies(server)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        // set options
        console.log("Loading currency options: ", data)
        setCurrencyOptions(data); 
      })
      .catch((err) => {
        console.error("Currency option error:", err, " Loading default options.")
      });

  }, [server]);


  const columnDefs = useMemo(() => {
    return [
      identity_group,
      tenor_group,
      rate_group,
      price_group(currenciesOptions)
    ];
  }, [currenciesOptions]);

  // notify editing to the server, update
  const onCellEditRequest = useCallback((event) => {

    const oldData = event.data;
    const field = event.colDef.field;
    const oldValue = event.oldValue;
    var newValue = event.newValue;
  
    // row info
    const newData = { ...oldData };
    newData[field] = newValue;

    // capture id from actual data
    const id = newData.id; 

    // check same value, return if no changes
    if (oldValue === newValue) {
      return;
    }

    let server_transaction = {}
    server_transaction[field] = newValue;

    // identifier, get name if available, otherwise ISIN
    async function bond_update_call() {

      if (oldData.name) {
        return bond_update(server, oldData.name, server_transaction);
      } else if (oldData.isin) {
        return bond_update_by_isin(server, oldData.isin, server_transaction);
      } else {
        console.error("No name, nor isin for specified bond")
      }
    }
  
    // notify server and await for response
    fetch_json_check(bond_update_call)
      .catch((error) => {
        console.error("An error occurred while updating bond, returning to previous state", error)
    
      })

  }, [server]);



  const defaultColDef = useMemo(() => {
      return {
        sortable: true,
        flex: 1,
        resizable: true,
        filter: true,
      };
  }, []);

  // TODO: update bond function


  return (
    <div style={gridStyle} className="ag-theme-balham">
      <AgGridReact
        ref = {ref}
        columnDefs={columnDefs}
        rowSelection={'multiple'}
        defaultColDef={defaultColDef}
        getRowId={(row) => row.data.id}
        undoRedoCellEditing={false}
        readOnlyEdit={true} 
        onCellEditRequest={onCellEditRequest}
        {...props}            
      ></AgGridReact>
    </div>
  )
});
