import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = { page : 0 };

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage(state,action) {
      state.page = action.payload.page;
    },
    setHome(state) {
      state.page = 0;
    }
  },
});

export default pageSlice;
