import { 
  NumberInput, 
  NumberInputField,
  IconButton,
  Stack,
  HStack,
  Flex,
  Text } from '@chakra-ui/react'

import { CheckIcon } from '@chakra-ui/icons';

import { useState, useRef, useEffect, useContext, useCallback} from 'react';

import { useSelector, useDispatch } from "react-redux";
import loadersSlice from "../store/slices/loaders";

import { DatabaseServer, 
  bond_update, 
  bond_info,
  fetch_json_check
} from 'modules/database';


export default function MarketBidSelector({setTempMarketBid, speculative}) {

  // get current bond from slices
  const bond = useSelector(state => state.loaders.bond);

  // track floating state
  const [marketBid, setMarketBid] = useState(null);

  // track if it's in grey mode
  const [inGrey, setInGrey] = useState(true);

  // Use server
  const server = useContext(DatabaseServer);

  // confirmed selection, upload to server
  const selectMarketBid = useCallback((result) => {
    
    if (!bond) {
      // nothing to do
      return;
    }

    if (!inGrey) {

      // get bond info on component mount
      fetch_json_check(() => bond_update(server, bond, {'market_bid' : result}))
        .catch((err) => {
          
          console.error("Market bid impossible to update for bond: ", bond, " Error: ", err);
        });

    } else {
      
      // set temporary market bid
      setTempMarketBid(result);
    }

  }, [server, inGrey, bond, setTempMarketBid]);


  useEffect(() => {

    if (!bond) {
      // nothing to do
      return;
    }

    // get bond info on component mount
    fetch_json_check(() => bond_info(server, bond))
      .then((data) => {
        
        // assign stored mkt bid
        setMarketBid(data.market_bid);

        // set in grey
        setInGrey(data.issue_price === null);

      }).catch((err) => {
        
        console.error("Market bid impossible to get for bond: ", bond);
      });

  }, [server, bond, setMarketBid, setInGrey]);


  function confirm() {

    if (  (inGrey && !speculative)
       || ((!inGrey || speculative) && marketBid >= 0)) {
      selectMarketBid(marketBid ? marketBid : null);
    }
  }

  function handleKeyPress(e) {
    
     // This is perfectly safe in react, it correctly detect the keys
    if(e.key == 'Enter'){
        confirm()
      }
  }

  function focusLost(e) {
    confirm()
  }

  function title_appendix() {
    if (inGrey && speculative) {
      return ' (speculative)';
    } else if (inGrey) {
      return ' (difference)';
    } else {
      return '';
    }
  }

  const title = 'Market BID' + title_appendix();

  return (
    <Stack>
      <Text fontSize='sm' as='u'>{title}</Text>
        <NumberInput 
          value={marketBid ? marketBid : ''} 
          onChange={(value) => setMarketBid(value)}
          onKeyDown={handleKeyPress}
          onBlur={focusLost}
          disabled={!bond}
        >
          <NumberInputField />
        </NumberInput>
    </Stack>
  );
}
