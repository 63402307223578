import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = { bond : null, broker : null, mkt_bid_changed : false };

const loadersSlice = createSlice({
  name: "loaders",
  initialState,
  reducers: {
    setBond(state,action) {
      state.bond = action.payload;
    },
    setBroker(state, action) {
      state.broker = action.payload;
    },
    toggleMktBidChanged(state) {
      state.mkt_bid_changed = !state.mkt_bid_changed;
    },
    clear(state) {
      state.bond = null;
      state.broker = null;
    }
  },
});

export default loadersSlice;
