import React, {  forwardRef, useContext, useCallback, useState, useEffect } from 'react';

import { 
  DefaultTable, 
  bbm_id,
  transaction_id,
  trade_status,
  trade_side,
  bond_name,
  quantity,
  market_price,
  user_name,
  broker_name,
  broker_alias,
  trade_date,
  settlement_date,

} from 'modules/default_table';

import 'styles/table.css'



/* Positioning specific states */

export const weight = {
  headerName: 'Weight',  
  field : 'weight',
  valueFormatter : function(params) {
    return (100. * params.value).toFixed(3) + ' %';
  }
}

export const wap = {
  headerName: 'WAP',  
  field : 'wpa',
  valueFormatter : function(params) {
    if (!params.value) {
      return '';
    }

    if (params.data.market_price_value) {
      return params.value.toFixed(3);
    } else {
      const symb = (params.value > 0) ? "+" : "";
      return symb + params.value.toFixed(3);
    }
  },
  cellClassRules: {
      // Green to Sell
    'market-price-issued': params => params.data.market_price_value,
    // Orange to Buy
    'market-price-grey': params => !params.data.market_price_value
  },
}

// Modify quantity to hide it by default
const positioning_quantity = Object.assign(quantity, {
  columnGroupShow: 'open', 
  });


const positioning_group = {
  headerName: 'Positioning', 
  headerClass: 'positioning_style',
  children:  [
    market_price,
    positioning_quantity,
    weight,
    wap
  ],
  openByDefault : true
};

const actors_group = {
  headerName: 'Actors', 
  headerClass: 'actors_style',
  children:  [
    user_name, 
    Object.assign({columnGroupShow: 'open'}, broker_name),
    broker_alias
  ]
};

const positioning_trade_date = Object.assign({
  columnGroupShow: 'open', 
}, trade_date)



const time_group = {
  headerName: 'Time', 
  headerClass: 'time_style',
  children:  [
    positioning_trade_date, 
    settlement_date
  ]
};

const PositioningTable = React.forwardRef((props, ref) => {

  const positioning_id = Object.assign({ cellClassRules: {
    // Green to Sell
    'sell-green': params => params.data.trade_side === 'sell',
    // Orange to Buy
    'buy-orange': params => params.data.trade_side === 'buy'
  } }, transaction_id);


  const [columnDefs, setColumnDefs] = useState([
    bbm_id,
    trade_side,
    actors_group,
    time_group,
    positioning_group
  ]);

  return (
    <DefaultTable
      ref={ref}
      columnDefs={columnDefs}
      readOnlyEdit={true}
      {...props}
    ></DefaultTable>
  );
});

export default PositioningTable;

