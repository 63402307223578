import {
  Stack,
  Text,
  Tag,
  Code,
  Spacer,
  HStack
} from '@chakra-ui/react';



export function TransactionChecksum ({title, sum}) {

  // identify transactions by
  // ID (remote)
  // bond_name
  // trade_side
  // broker_name
  
  const side_scheme = sum.trade_side === 'buy' ? 'orange' : 'green';

  return (
    <>
      <Text>{title}</Text>
      <Stack> 
        {sum.bbm_id && <Code>{sum.bbm_id}</Code>},
        <Text as='b'>{sum.bond_name}</Text>,
        <HStack>
          <Code>Qty: {sum.quantity}K {sum.currency}</Code>
          <Spacer/>
          <Tag size='md' colorScheme={side_scheme}>{sum.trade_side}</Tag>,
        </HStack>
        <Text>{sum.broker_name}</Text>
      </Stack>
    </>
  );
}

export function BondChecksum ({action, sum}) {

  const open_scheme = sum.open_position ? 'teal' : 'red';
  const open_text = sum.open_position  ? 'Open' : 'Closed';

  return (
    <>
      <Text>Bond</Text>
      <Stack> 
        {sum.name && <Text as='b'>{sum.name}</Text>}
        <HStack>
          {sum.isin && <Code>{sum.isin}</Code>}
          <Spacer/>
          <Tag size='md' colorScheme={open_scheme}>{open_text}</Tag>
        </HStack>
      </Stack>
      <Text>was {action}</Text>
    </>
  );

}


export function BrokerChecksum ({action, sum}) {


  return (
    <>
      <Text>Broker</Text>
      <Stack> 
        <Text as='b'>{sum.name}</Text>
        {sum.alias && <Code>{sum.alias}</Code>}
      </Stack>
      <Text>was {action}</Text>
    </>
  );

}

