import { useState, useContext } from "react";

import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Text,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement
} from "@chakra-ui/react";

import { useForm } from 'react-hook-form'

import { FaUserAlt, FaLock } from "react-icons/fa";
import { DatabaseServer, login } from "modules/database"
import authSlice from "../store/slices/auth";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);



const LoginIndex = () => {

  const server = useContext(DatabaseServer);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState()

  const dispatch = useDispatch();
  const history = useNavigate();

  function handleLogin(username, password) {
    
    // attempt login
    login(server, username, password)
    .then((res) => {

      if (!res.ok) {
        return Promise.reject(res);
        //throw new Error(res.json());
      }

      return res.json();

    }).then((res) => {

      console.log("Dispatching")
      dispatch(
        authSlice.actions.setAuthTokens({
          token: res.access,
          refreshToken: res.refresh, 
        })
      );
      dispatch(authSlice.actions.setAccount(res.user));
      setLoading(false);
      history("/");

    }).catch((err) => {
      if (err.status == 401) {
        setMessage("Wrong username or password");
      } else {
        setMessage("Something went wrong, check internet connection");
      }
    });
  }


  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  // show or hide
  const errorMessage = message ? <Text color='red'> {message} </Text> : null;

  // manage form submission
  function on_submit(values) {
    setLoading(true)
    handleLogin(values.username, values.password);
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="teal.500" />
        <Heading color="teal.400">Earlyworld</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit(on_submit)}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input 
                    type="text" 
                    placeholder="Username" 
                    {...register("username", { required: true })}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    {...register("password", { required: true })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {/*<FormHelperText textAlign="right">
                  <Link>forgot password?</Link>
                </FormHelperText>*/}
              </FormControl>
              {errorMessage}
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
                disable={loading}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      {/*<Box>
        Need an account{" "}
        <Link color="teal.500" href="#">
          Sign Up
        </Link>
      </Box>*/}
    </Flex>
  );
};

export default LoginIndex;
