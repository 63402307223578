import React, {  forwardRef, useCallback, useState, useRef, useEffect, useContext } from 'react';

import { 
  DefaultTable, 
  transaction_id,
  trade_status,
  trade_side,
  bond_group,
  bond_name,
  bond_isin,
  user_name,
  broker_name,
  broker_alias,
  actors_group,
  time_group,
  trade_date,
  trade_time,
  settlement_date,
  transaction_group,
  security,
  comments,
  aux_group,
  currency,
  market_price,
  quantity,
  bbm_id,
  redefineGroupChildren
} from 'modules/default_table';

import { 
  update_transaction, 
  load_trades, 
  DatabaseServer, 
  changeDateFormats, 
  currencies, 
  bonds_list,
  brokers_list,
  fetch_json_check
} from 'modules/database'

import {
  useBondsSource,
  useBrokersSource
} from 'modules/eventsources';



import { convertISOToDate, dateToDB } from 'modules/datetime';



export const LocalTradesTable = React.forwardRef((props, ref) => {

  const server = useContext(DatabaseServer);

  // bond names selection by useState
  const [bondOptions, setBondOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);

  // fetch name selection
  const fetch_bonds = useCallback(() => {
  
    fetch_json_check(() => bonds_list(server))
      .then((data) => {
        
        console.log("Local trades table: names = ", data)
        setBondOptions(data);
 
      }).catch((err) => {
        console.error("Something went wrong fetching bond names: ", err);
      });

  }, [server, setBondOptions]);


  // fetch broker selection (names)
  const fetch_brokers = useCallback(() => {
    
    fetch_json_check(() => brokers_list(server))
      .then((data) => {
        
        console.log("Local trades table: broker name = ", data)
        setBrokerOptions(data);
 
      }).catch((err) => {
        console.error("Something went wrong fetching broker names: ", err);
      });

  }, [server, setBrokerOptions]);



  // initialization
  useEffect(() => {

    console.log("Fetching options")
    
    fetch_bonds();
    fetch_brokers();

  }, []);

  // when bonds are present in the table, values must be updated
  const updateTableWithBond = useCallback((event) => {

    // update bond options

    switch (event.action) {
      case 'create':
        // add name
        // add isin
        setBondOptions((opts) => {

          const {id, name, isin, ...other_data} = event.data;

          return [...opts, {id, name, isin}];
        });

        break;

      case 'update':
        // update name
        // update isin

        if (event.changed.includes('name') || event.changed.includes('isin')) {
        
          setBondOptions((opts) => {

            const {id, name, isin, ...other_data} = event.data;

            const excluded = opts.filter(el => el.id !== id);

            return [...excluded, {id, name, isin}];
          });
        }

        break;

      case 'delete':
        // remove name
        // remove isin
        setBondOptions((opts) => {

            const {id, ...other_data} = event.data;

            return opts.filter(el => el.id !== id);
          });

        break;
    }
  }, [setBondOptions]);

  const updateTableWithBroker = useCallback((event) => {

    // update broker options
    
    switch (event.action) {

      case 'create':
        // add
        setBrokerOptions((opts) => {

          const {id, name, alias, ...other_data} = event.data;

          return [...opts, {id, name, alias}];
        });

        break;

      case 'update':

        // update 
        if (event.changed.includes('name') || event.changed.includes('alias')) {
        
          setBrokerOptions((opts) => {

            const {id, name, alias, ...other_data} = event.data;

            const excluded = opts.filter(el => el.id !== id);

            return [...excluded, {id, name, alias}];
          });
        }

        break;
      case 'delete':

        // remove
        setBrokerOptions((opts) => {

          const {id, name, alias, ...other_data} = event.data;

          return opts.filter(el => el.id !== id);
        });

        break;
    }
  }, [setBrokerOptions]);


  // synchronization effect
  useBondsSource(updateTableWithBond);
  useBrokersSource(updateTableWithBroker);


  // Define bond name
  const local_bond_name = Object.assign({
    'cellEditor': 'agSelectCellEditor',
    'cellEditorParams': {
      values : bondOptions ? bondOptions.map(opt => opt.name).filter(name => name) : []
      },
    'editable' : params => params.data.bbm_id === null
  }, bond_name);

  // Define bond isin
  const local_bond_isin = Object.assign({
    'cellEditor': 'agSelectCellEditor',
    'cellEditorParams': {
      values : bondOptions ? bondOptions.map(opt => opt.isin).filter(isin => isin) : []
      },
    'editable' : params => params.data.bbm_id === null
    //'editable' : true
  }, bond_isin);

  const local_bond_group = redefineGroupChildren([
      local_bond_name,
      local_bond_isin
  ], bond_group);

  // Define broker name
  const local_broker_name = Object.assign({
    'cellEditor': 'agSelectCellEditor',
    'cellEditorParams': {
      values : brokerOptions ? brokerOptions.map(opt => opt.name).filter(name => name) : []

      },
    'editable' : params => params.data.bbm_id === null
    //'editable' : true
  }, broker_name);

  const local_broker_alias = Object.assign({
    'cellEditor': 'agSelectCellEditor',
    'cellEditorParams': {
      values : brokerOptions ? brokerOptions.map(opt => opt.alias).filter(alias => alias) : []
      },
    'editable' : params => params.data.bbm_id === null
    //'editable' : true
  }, broker_alias);

  /*const local_actors_group = Object.assign({
    'openByDefault' : true,
    'cc' : [
      user_name,
      local_broker_name,
      local_broker_alias
    ]
  }, actors_group);

  local_actors_group['children'] = local_actors_group['cc'];
  delete local_actors_group['cc']; */

  const local_actors_group = redefineGroupChildren([
      user_name,
      local_broker_name,
      local_broker_alias
  ], actors_group);

  local_actors_group['openByDefault'] = true;

  // redefine transaction group
  /*var local_transaction_group = Object.assign({
    'cc' : [
      Object.assign({ editable: true}, market_price),
      Object.assign({ editable: true}, quantity),
      currency
    ]
  }, transaction_group);

  local_transaction_group['children'] = local_transaction_group['cc'];
  delete local_transaction_group['cc'];*/

  const local_transaction_group = redefineGroupChildren([
      Object.assign({ 
        'editable' : params => params.data.bbm_id === null
        //editable: true
      }, market_price),
      Object.assign({ 
        'editable' : params => params.data.bbm_id === null
        //editable: true
      }, quantity),
      currency
  ], transaction_group);

  console.log("Old transaction group: ", transaction_group)
  console.log("Local transaction group: ", local_transaction_group)

  // redefine transaction group
  /*var local_time_group = Object.assign({
    'cc' : [
      trade_time,
      trade_date,
      Object.assign({ editable: true}, settlement_date),
    ]
  }, time_group);

  local_time_group['children'] = local_time_group['cc'];
  delete local_time_group['cc'];*/

  const local_time_group = redefineGroupChildren([
      trade_time,
      trade_date,
      Object.assign({ 
        'editable' : params => params.data.bbm_id === null
        //editable: true
      }, settlement_date)
  ], time_group);


  const local_aux_group = redefineGroupChildren([
      Object.assign({ 
        'editable' : params => params.data.bbm_id === null
        //editable: true
      }, security),
      Object.assign({ 
        'editable' : params => params.data.bbm_id === null
        //editable: true
      }, comments),
  ], aux_group);

  // redefine bbm_id to be editable
  // choose among the registered bond and blot recorded
  const local_bbm_id = Object.assign({
    // TODO: custom editor, bond based
    //'cellEditor': 'agSelectCellEditor',
    //'cellEditorParams': {
    //  values : brokerAliases
    //  },
    editable : true
  }, bbm_id);

  
  const columnDefs = [
    local_bbm_id,
    trade_status,         
    Object.assign({ 
      'editable' : true
    }, trade_side),             
    local_bond_group,
    local_actors_group,
    local_time_group,
    local_transaction_group,
    local_aux_group
  ];

  // notify editing to the server, update
  const onCellEditRequest = useCallback((event) => {

    console.log("Cell edit request")

    const oldData = event.data;
    const field = event.colDef.field;
    const oldValue = event.oldValue;
    var newValue = event.newValue;
  
    // correct settlement date
    if (field === 'settlement_datetime') {
      newValue = dateToDB(newValue);
    }
    
    // row info
    const newData = { ...oldData };
    newData[field] = newValue;

    // capture id from actual data
    const id = newData.id; 

    //console.log("Change {$field} from {$oldValue} to {$newValue}")
    
    // check same value, return if no changes
    if (oldValue === newValue) {
      return;
    }

    let server_transaction = {}
    server_transaction[field] = newValue;
  
    // notify server and await for response
    fetch_json_check(() => update_transaction(server, id, server_transaction))
      .catch((error) => {

        // TODO: notify
        console.error("An error occurred while updating transaction, returning to previous state", error)
    
      })

  }, [server]);

  console.log("Column def: ", columnDefs)

  return (
  <DefaultTable
    ref={ref}
    columnDefs={columnDefs}
    rowSelection={'multiple'}
    undoRedoCellEditing={false}
    onCellEditRequest={onCellEditRequest}
    suppressHorizontalScroll={false}
    readOnlyEdit={true} 
    {...props}
  ></DefaultTable>
  );
});



