import React from 'react'

import {
  Icon,
  Text,
  Flex,
  SlideFade,
  Button,
  Divider,
  HStack
} from '@chakra-ui/react';


import { FiAlignLeft, FiX } from "react-icons/fi";

export default function MenuTrigger({isOpen, onOpen, onClose}) {

  return (
    <Flex  p='4' align='center'>

      <SlideFade in={isOpen} >
        <Button onClick={onClose}>
          <Icon as={FiX}/>
        </Button>
      </SlideFade>

      <SlideFade in={!isOpen}>
        <Button onClick={onOpen}>
          <HStack>
            <Icon as={FiAlignLeft}/> 
            <Divider orientation='vertical'/>
            <Text>Sections</Text>
          </HStack>
        </Button>
      </SlideFade>

    </Flex>
  );
}

