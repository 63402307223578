import React, {useState, useContext, useCallback, useEffect, useRef} from 'react';

import { DatabaseServer, bond_delete } from 'modules/database';


import {
  Button,
  ScaleFade,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';


export default function EraseButton({gridRef, colorScheme, selectedRowsNb}) {

  console.log("Ref grid: ", gridRef.current)

  // Regular open/close of chakra ui
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  // Transition for functionality activation
  const [ show, setShow ] = useState(false);
  
  useEffect(() => {

    setShow((selectedRowsNb > 0) === true);

  }, [selectedRowsNb]);

  // Use server
  const server = useContext(DatabaseServer);
  
  // confirm
  const onConfirm = useCallback(() => {
    
    const selectedData = gridRef.current.api.getSelectedRows();

    for (const row of selectedData) {
      
      bond_delete(server, row.id).
        then((res) => {
          if (!res.ok){
            return Promise.reject(res);
          }

          console.log("Successfully delete bond: ", row);

          gridRef.current.api.applyTransaction({ remove: [{id : row.id}] });
        }).
        catch((err) => {
          console.error("An error occurred while closing position: ", row.bond_name, ", ", err);
        });

   }

  }, [server]);

  // actual effect: close, act and catch errors
  function effect_and_close() {
    onClose();
    onConfirm();
  }

  return (
    <>
      <ScaleFade initialScale={0.9} in={show} unmountOnExit={true}>
        <Button colorScheme={colorScheme} onClick={onOpen}>
          Delete positions
        </Button>
      </ScaleFade>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete positions
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure to completely delete {selectedRowsNb} positions?
              This operation will also delete all associated transactions.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Keep
              </Button>
              <Button colorScheme={colorScheme} onClick={effect_and_close} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
