import React from 'react';

import {
  Flex,
  Text,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorMode,
} from '@chakra-ui/react';


// Here we have used react-icons package for the icons
import { SettingsIcon } from '@chakra-ui/icons'
import authSlice from "store/slices/auth";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function MenuSettings() {

  const { toggleColorMode } = useColorMode()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handle_logout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
  };


  return (
      <Flex px="4" justify="center" alignItems="center">
        <Menu>
          <MenuButton
            as={Button}
            size={'sm'}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            _hover={{ textDecoration: 'none' }}
          >
            
            <HStack>
              <SettingsIcon size={'sm'}/>
              <Text>Settings</Text>
            </HStack>

          </MenuButton>

          <MenuList fontSize={17} zIndex={5555}>
            {/*<MenuItem> Database </MenuItem>*/}
            <MenuItem onClick={handle_logout}> Log out </MenuItem>
            {/*<MenuItem onClick={toggleColorMode}> Dark/White theme </MenuItem>*/}
          </MenuList>

        </Menu>
      </Flex>
    );
}
