import React from 'react';

import BondMatchForm from 'modules/bond_match_form';
import BondClose from 'modules/bond_close';
import BondReopen from 'modules/bond_reopen';
import BondCreateForm from 'modules/bond_create_form'
import CloseButton from './close_button';
import EraseButton from './erase_button';

import {
  Button,
  ButtonGroup,
  Divider
} from '@chakra-ui/react';


export default function ActionButtons({refreshTable, gridRef, selectedRowsNb}) {
  
  console.log("Selected rows: ", selectedRowsNb)

  return (
    <ButtonGroup>

      <BondCreateForm />
      <CloseButton 
        gridRef={gridRef}
        colorScheme='red'
        selectedRowsNb={selectedRowsNb}
      />
      <EraseButton 
        gridRef={gridRef}
        colorScheme='blackAlpha'
        selectedRowsNb={selectedRowsNb}
      />
      <BondReopen />
      
    </ButtonGroup>
  );
}
