
export function now() {

  /* Get current date */
  return new Date();
}

/*
 * Map serialization for server communication
 */

// convert timestamp in seconds into javascript Date object
export function dateFromDB(unix_timestamp) {
  
  if (unix_timestamp) {
    return new Date(unix_timestamp * 1000);
  } else {
    return null;
  }
}

// convert javascript Date object into timestamp in seconds
export function dateToDB(date) {

  return Math.floor(date.getTime() / 1000);
}

/*
 *  Other format or functionalities
 */

export function isoToday() {

  /* Get date */
  var t = new Date();

  /* Adjust by Time zone offset */
  var z = t.getTimezoneOffset() * 60 * 1000;
  var tLocal = t-z;
  tLocal = new Date(tLocal);

  /* Convert to ISO format */
  var iso = tLocal.toISOString();

  /* Drop milliseconds */
  return iso.split(".")[0];
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatLocal(date) {

  //YYYY-MM-DDThh:mm
  
  const date_part = [
    date.getFullYear(),                 // Year
    padTo2Digits(date.getMonth() + 1),  // Month
    padTo2Digits(date.getDate()),       // Day
  ].join('-');

  const time_part = [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes())
  ].join(':');

  return date_part + 'T' + time_part;
}


// tool to convert input type dates in Date
export function dateToDatetime(date, ref_dt) {

  var copiedDate = new Date(ref_dt.getTime());

  // gg/mm/aaaa
  const splitted = date.split('-')

  copiedDate.setFullYear(parseInt(splitted[0]));
  copiedDate.setMonth(parseInt(splitted[1]) - 1, parseInt(splitted[2]));

  return copiedDate;
}

// date comparison
export function geDates(lhs, rhs) {

  return lhs.getTime() >= rhs.getTime();
}

export function formLocalToDate(date) {
}

export function separeISODateTime(date) {

  // compute separated date time in +00 zone
  var arr = date.toISOString().slice(0, 19).replace('T', ' ').split(' ');

  return { 'date' : arr[0], 'time' : arr[1] };
}

export function isDate(date) {
  return date instanceof Date && !isNaN(date.valueOf());
}

/*
 * Database specific formatting
 */

// TODO: manage time zone
export function formatDateDB(date) {
  if (!date || date === undefined) {
    return null;
  }

  return [
    date.getFullYear(),                 // Year
    padTo2Digits(date.getMonth() + 1),  // Month
    padTo2Digits(date.getDate()),       // Day
  ].join('-');
}

/*
 * Visualization formatting
 */

export function formatDateUser(date) {
  if (!date || date === undefined) {
    return "";
  }

  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth()+1),
    date.getFullYear(),
  ].join('-');
}

export function formatTimeUser(time) {
  if (!time || time === undefined) {
    return "";
  }

  return time.toLocaleTimeString();
}


/*
 *  String to date
 */
export function convertISOToDate(strValue) {

  if (!strValue || strValue === '') {
    return null;
  }

  // YYYY-mm-dd
  const splitted = strValue.split('-');

  return new Date(parseInt(splitted[0]), 
                  parseInt(splitted[1] - 1), 
                  parseInt(splitted[2]));
}

/*
 * Time parsing
 */

export function parseTime(time_str) {
  const splitted = time_str.split(':');

  return {hour : splitted[0], minutes : splitted[1]};
}

