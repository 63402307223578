import { 
  memo, 
  forwardRef,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';

import Select from 'react-select';
import DateRangeFilter from 'modules/date_range_filter';

import {
  Button,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  ListItem,
  UnorderedList,
  Text,
  HStack,
  Box,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import {
  ChevronDownIcon
} from '@chakra-ui/icons';

import { 
  DatabaseServer, 
  bond_names, 
  bond_isins, 
  bonds_by_date_range, 
  bonds_by_open_positions, 
  fetch_json_check,
  matchbbm_search
} from 'modules/database';

import { dateFromDB } from "modules/datetime";

import { useSelector } from "react-redux";

import { useForm, Controller } from 'react-hook-form'


const stampsToDate = (stamps) => {

  const isDateDefined = stamps.startDate && stamps.endDate;

  return isDateDefined ? 
      {startDate : dateFromDB(stamps.startDate), endDate : dateFromDB(stamps.endDate)}
    : {startDate : new Date(), endDate : new Date()};
}


// option creator
const createOption = (label) => ({
  label,
  value: label,
});

const reduceOptions = (array) => array.map(elem => elem.value);



const AddOptionsMenu = forwardRef( ({addNames, addISINs}, ref) => {
  
  // date range modal disclosure
  const { isOpen, onOpen, onClose } = useDisclosure();

  // get dateRange
  const initialDateRange = useSelector(state => state.dateRange);
  const [dateRange, setDateRange] = useState(stampsToDate(initialDateRange));

  const server = useContext(DatabaseServer);

  const today_request = useCallback(() => {

    console.log("Today request");

    fetch_json_check(() => bonds_by_date_range(server, new Date(), new Date()))
      .then((data) => {
        
        if (data.names) {
          addNames(data.names);
        }
        
        if (data.isins) {
          addISINs(data.isins);
        }

      }).catch((errors) => {
        console.error("Error in today request: ", errors)
      });

  }, [server]);

  const date_range_request = useCallback(() => {

    console.log("Date range request: ", dateRange);

    fetch_json_check(() => bonds_by_date_range(server, dateRange.startDate, dateRange.endDate))
      .then((data) => {
        
        if (data.names) {
          addNames(data.names);
        }
        
        if (data.isins) {
          addISINs(data.isins);
        }

        onClose();

      }).catch((errors) => {
        console.error("Error in date range request: ", errors)
      });


  }, [server, dateRange]);

  const open_positions_request = useCallback(() => {

    console.log("Open positions request");
    fetch_json_check(() => bonds_by_open_positions(server, dateRange.startDate, dateRange.endDate))
      .then((data) => {

        if (data.names) {
          addNames(data.names);
        }
        
        if (data.isins) {
          addISINs(data.isins);
        }

        onClose();

      }).catch((errors) => {
        console.error("Error in open positions request: ", errors)
      });

  }, [server]);
 
  
  return (
    <>
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        Add
      </MenuButton>
      <MenuList>
        <MenuItem onClick={today_request}>Today's bond</MenuItem>
        <MenuItem onClick={onOpen}>Bonds by date range</MenuItem>
        <MenuItem onClick={open_positions_request}>Open positions</MenuItem>
      </MenuList>
    </Menu>

    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange}/>
          <Button colorScheme='blue' mr={3} ml={3} mt={4}  onClick={onClose}>
            Close
          </Button>
          <Button variant='ghost' mt={4} onClick={date_range_request}>Confirm</Button>
          </HStack>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
});


const components = {
  DropdownIndicator: null,
};



/*
 *  Component of Multi Input
 *  To insert more brokers at the time
 */
const BondMultiInput = forwardRef( ({name, value, onChange, optionsLoader, placeholder, onError}, ref) => {
  
  // activation
  const [isLoading, setIsLoading] = useState(false);

  // Use server
  const server = useContext(DatabaseServer);

  // Bond options
  const [options, setOptions] = useState([]);
  

  // fetch active bonds
  useEffect(() => {

    //console.log("Server change effect")
    
    setIsLoading(true);

    optionsLoader(server)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        

        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = createOption(el);
        });
        
        console.log("Loaded options: ", data);

        // set options
        setOptions(data); 
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Bond option error:", err)
      });

  }, [server, setOptions, setIsLoading])
   

  return (
    <Select
      ref={ref}
      isMulti
      value={value}
      onChange={onChange}
      name={name}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={placeholder} 
    />
  );

});


export default function MatchLoader({onDataLoaded}) {
  
  /*
   * 1. Bonds: 
   *  1.1 Multiselect by bond name
   *  1.2 Multiselect by ISIN
   * 2. "Add" menu 
   *  2.1 "Add" Date Range: add all bonds in the range
   *  2.2 "Add" Open positions
   *  2.3 "Add" Today
   * 3. Match! button
   */


  const ControlledBondNameInput = memo(({control}) => {
    
    return (
      <Controller
        name={'names'}
        control={control}
        render={
          ({field: { onChange, value, name, ref }}) => (
            <BondMultiInput
              ref={ref}
              name={name}
              value={value}
              onChange={onChange}
              optionsLoader={bond_names}
              placeholder={"Select bond names"} 
            />
          )}
        rules={{ required: false }}
      />
    );
  });

  const ControlledBondISINInput = memo(({control}) => {

    return (
      <Controller
        name={'isins'}
        control={control}
        render={
          ({field: { onChange, value, name, ref }}) => (
            <BondMultiInput
              ref={ref}
              name={name}
              value={value}
              onChange={onChange}
              optionsLoader={bond_isins}
              placeholder={"Select bond isins"} 
            />
          )}
        rules={{ required: false }}
      />
    );
  });

/* Form */
  const {
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const server = useContext(DatabaseServer);

  function onSubmitForm(data) {

    console.log(data)

    data = {
      names : data.names ? reduceOptions(data.names) : [],
      isins : data.isins ? reduceOptions(data.isins) : []
    };

    console.log("Searching for matches...")
    console.log("Names: ", data.names)
    console.log("ISINs: ", data.isins)
    
    
    matchbbm_search(server, data.names, data.isins)
      .then((loaded_data) => {

        // submission callback
        onDataLoaded(loaded_data);

      }).catch((errors) => {
      
        console.error("Match bbm: ", errors);
      });
  }
  
  const addNames = useCallback((names) => {

    const oldNames = watch("names");

    // append
    const newValue = new Set(oldNames ? [...oldNames.map(el => el.value), ...names] : names);

    // set and remove duplicates
    setValue('names', [...newValue].map(createOption));

  }, [watch, setValue]);


  const addISINs = useCallback((isins) => {

    const oldIsins = watch("isins");

    // append
    const newValue = new Set(oldIsins ? [...oldIsins.map(el => el.value), ...isins] : isins);
    
    // set and remove duplicates
    setValue('isins', [...newValue].map(createOption));

  }, [watch, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <HStack>
        <ControlledBondNameInput control={control} />
        <ControlledBondISINInput control={control} />
        <AddOptionsMenu addNames={addNames} addISINs={addISINs}/>
        <Button colorScheme='teal' onClick={handleSubmit(onSubmitForm)}>
          Search
        </Button>
        <Button colorScheme='red' onClick={reset}>
          Reset
        </Button>
      </HStack>
    </form>
  );
}
