import React from 'react';

import {
  VStack,
  HStack,
  StackDivider,
  Text,
  Flex,
  Spacer,
  Divider
} from '@chakra-ui/react';

import { useSelector } from "react-redux";

import MenuSettings from 'menu/menusettings'
import SideMenu from 'menu/sidemenu';
import TitledView from 'modules/titled_view'

export default function Section({title, children}) {

  // show user name
  const username = useSelector(state => state.auth.account.username);

  return (
    <>
      <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={3}
          align='stretch'
        >
        <Flex >
            <SideMenu/>
            <Spacer/>
          <HStack>
            <Text pt='4' pr='3' fontSize='3xl'>{title} </Text>
            <Divider orientation='vertical' />
            <TitledView title='Logged as'>
              <Text mr='3' fontSize='3xl'>{username} </Text>
            </TitledView>
            <Divider orientation='vertical' />
            <MenuSettings/>
          </HStack>
        </Flex>
        {children}
      </VStack>
    </>
   );
}
