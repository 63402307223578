import React, { 
  useState, 
  useRef, 
  useEffect, 
  useContext,
  useCallback
} from 'react';

import { 
  DatabaseServer, 
  active_users,
  fetch_json_check
} from 'modules/database';

import {
  Box,
} from '@chakra-ui/react';

import TitledView from 'modules/titled_view';

import { useSelector, useDispatch } from "react-redux";

import loadersSlice from "../store/slices/loaders";

import Select from 'react-select';


// tool
const createOption = (val) => { 
  return {'value' : val, 'label' : val}; 
}


export default function UserSelector({user, onUserChange}) {

  // Use server
  const server = useContext(DatabaseServer);

  // Bond options
  const [userOptions, setUserOptions] = useState([]);

  // Selector
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const selectRef = useRef();

  // fetch active bonds
  const reloadOptions = useCallback(() => {

    console.log("Fetching user options: ", server)
    

    fetch_json_check(() => active_users(server))
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = {'value' : el, 'label' : el};
        });

        
        // set options
        setUserOptions(data); 

        console.log("Got user options: ", data)
        
      })
      .catch((err) => {
        console.error("User option error:", err)
      });

  }, [server, setUserOptions])


  useEffect(() => {
    reloadOptions();
  }, [server]);


  useEffect(() => {

    if (userOptions.length === 0) {

      // disable select
      setIsLoading(true);
      setIsDisabled(true);

    } else {
      
      // now options are available

      // enable select
      setIsLoading(false);
      setIsDisabled(false);

      //selectRef.current.selectOption(createOption(bond));

    }

  }, [userOptions]);


  return (
    <TitledView title='User name'>
      <Box minW='200px'>
      <Select
        ref ={selectRef}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={true}
        isRtl={false}
        isSearchable
        name={"User name"}
        options={userOptions}
        value={userOptions && user ? createOption(user) : null}
        onChange={opt => onUserChange(opt ? opt.value : null)}
      />
      </Box>
    </TitledView>
  );
}
