import React, { useState, useEffect, forwardRef, useContext } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form'


import { DatabaseServer, currencies } from './database'

function createOption(value) {
  return {
    value,
    label : value
  };
}

export function AutocompleteCurrencyInput ({id, control, rules}, ref) {

  //className="basic-single"
  //classNamePrefix="select"
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  // Use server
  const server = useContext(DatabaseServer);

  // Currencies options
  const [options, setOptions] = useState([{'value' : "GBP", 'label' : "GBP"}]);

  // fetch active bonds
  useEffect(() => {

    //console.log("Server change effect")
    
    setIsLoading(true);

    currencies(server)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = createOption(el);
        });
        
        // set options
        setOptions(data); 
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Currency option error:", err)
      });

  }, [server])

  //defaultValue={options[0]}
  return (
    <Controller
        name={id}
        control={control}
        render={
          ({field: { onChange, value, name, ref }}) => (
            <Select
              ref ={ref}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name={name}
              options={options}
              value={createOption(value)}
              onChange={opt => onChange(opt ? opt.value : null)}
            />
        )}
        rules={rules} 
      />
  );
}

