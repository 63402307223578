import React, {
  useState, 
  useRef,
  useEffect, 
  forwardRef,
  useCallback,
  useImperativeHandle
} from 'react';

import {
  Button,
  ScaleFade,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';


const CancelButton = forwardRef(({onConfirm, colorScheme}, ref) => {

  // Regular open/close of chakra ui
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Transition for functionality activation
  const [ show, setShow ] = useState(false);
  const [ selectedRowsNb, setSelectedRowsNb ] = useState(0);
  
  // internal button ref
  const buttonRef = useRef();
  
  // customize ref
  useImperativeHandle(ref, () => {
    return {
      setVisible(value) { setShow(value) },
      setRowsAlertNb(nb) { setSelectedRowsNb(nb) }
    };
  }, [setShow, setSelectedRowsNb]);

  
  // actual effect: close, act and catch errors
  function effect_and_close() {
    onClose();
    onConfirm();
  }

  

  return (
    <>
      <ScaleFade initialScale={0.9} in={show} unmountOnExit={true}>
        <Button colorScheme={colorScheme} onClick={onOpen}>
          Delete Transaction
        </Button>
      </ScaleFade>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={buttonRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete transaction
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure to permanently delete {selectedRowsNb} transaction? You won't able to undo this operation.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={buttonRef} onClick={onClose}>
                Keep
              </Button>
              <Button colorScheme={colorScheme} onClick={effect_and_close} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
});

export default CancelButton;

