
import NavItem from './navitem'

import {
  Flex,
  HStack
} from '@chakra-ui/react';

export default function MenuNav({setPage, onClose}) {

  // TODO: check conditional rendering vs routing

  return (
    <Flex pt="4" align="center">
      <HStack alignItems="flex-start">
        <NavItem label='Open positions' setPage={setPage} onClose={onClose} target={0}/>
        <NavItem label='Position details' setPage={setPage} onClose={onClose} target={1}/>
        <NavItem label='Transactions manager' setPage={setPage} onClose={onClose} target={2}/>
        <NavItem label='Bond manager' setPage={setPage} onClose={onClose} target={3}/>
        <NavItem label='Blot matching' setPage={setPage} onClose={onClose} target={4}/>
      </HStack>
    </Flex>
  );
}
