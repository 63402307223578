import React, 
  { useRef, 
    useState, 
    useEffect, 
    useContext, 
    useCallback,
    forwardRef
  }from 'react';
//import { useGridCellEditor } from "@ag-grid-community/react";
import { useGridCellEditor } from "ag-grid-react";

import { NumericFormat } from 'react-number-format';
import { Controller } from 'react-hook-form'

import {
  NumberInput
} from '@chakra-ui/react';

// Specific market price input
export default function MarketPriceInput(props) {

  return (
    <NumberInput>
    <NumericFormat 
      decimalScale={3} 
      fixedDecimalScale
      className="chakra-numberinput__field css-1g0mrv8"
      {...props}
    />
    </NumberInput>
    );
}

export const ControlledMarketPriceInput = forwardRef(({id, control, rules}, ref) =>  {
  return (
    <Controller
      ref={ref}
      name={id}
      control={control}
      render={
        ({field: { onChange, value, name, field_ref }}) => (
          <MarketPriceInput
            ref={field_ref}
            name={name}
            value={value}
            onChange={onChange}
          />
        )}
      rules={rules}
    />
    );
});

/*
 *
 * Ag-grid
 */

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

export const AGMarketPriceEditor = ({ value, onValueChange, eventKey, stopEditing }) => {

  const updateValue = (val) => {
        onValueChange(val === '' ? null : val);
    };

  const refInput = useRef(null);

  const isLeftOrRight = (event) => {
      return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  };

  const isCharNumeric = (charStr) => {
      return !!/^\d+$/.test(charStr);
  };

  const isNumericKey = (event) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
  };

  const isBackspace = (event) => {
      return event.key === KEY_BACKSPACE;
  };


  const finishedEditingPressed = (event) => {
        const key = event.key;
        return key === KEY_ENTER || key === KEY_TAB;
  };

  const onKeyDown = (event) => {

      if (isLeftOrRight(event) || isBackspace(event)) {
          event.stopPropagation();
          return;
      }

      if (!finishedEditingPressed(event) && !isNumericKey(event)) {
          if (event.preventDefault) event.preventDefault();
      }

      if (finishedEditingPressed(event)) {
          console.log("Finished editing")
          stopEditing();
      }
  };

   // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    const isCancelBeforeStart = useCallback(() => {
        return !!eventKey && eventKey.length === 1 && ('1234567890'.indexOf(eventKey) < 0);
    }, [eventKey]);

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    const isCancelAfterEnd = useCallback(() => {
        // will reject the number if it greater than 1,000,000
        // not very practical, but demonstrates the method.
        return value != null && value > 1000000;
    }, [value]);

    useGridCellEditor({
        isCancelBeforeStart,
        isCancelAfterEnd,
    });


  return (
    <NumericFormat 
      ref={refInput}
      decimalScale={3} 
      fixedDecimalScale
      value={value}
      onChange={e => updateValue(e.target.value)}
      onKeyDown={(event) => onKeyDown(event)}
    />
  );
}



