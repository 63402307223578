import React, {useState, useEffect, useRef, useContext} from 'react';

import {
  Button,
  ScaleFade,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';

import {
  bond_info,
  bond_close,
  DatabaseServer
} from 'modules/database';

import { useSelector } from "react-redux";


export default function ClosePositionButton() {

  // get bond name to close
  const bond_name = useSelector(state => state.loaders.bond);

  // Regular open/close of chakra ui
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  // Transition for functionality activation
  const [ show, setShow ] = useState(false);

  const server = useContext(DatabaseServer);

  useEffect(() => {
    
    bond_info(server, bond_name)
      .then((res) => {

        if (!res.ok) {
          return Promise.reject(res);
        }

        return res.json();

      }).then((data) => {
        
        // TODO: take into consideration the quantity balance
        
        // show only if aggregation data is zero
        setShow(data && data.open_position && data.market_bid);

      }).catch((err) => {
        console.error("Close position button, fetching info error. ", err);
      });

  }, [bond_name, server]);
  
  // confirm
  function onConfirm() {

    bond_close(server, bond_name).
        then((res) => {
          if (!res.ok){
            return Promise.reject(res);
          }

          console.log("Successfully closed bond: ", bond_name);
          
          // hide button
          setShow(false);
        }).
        catch((err) => {
          console.error("An error occurred while closing position: ", bond_name, ", ", err);
        });
  }

  // actual effect: close, act and catch errors
  function effect_and_close() {
    onClose();
    onConfirm();
  }

  return (
    <>
      <ScaleFade initialScale={0.9} in={show} unmountOnExit={true}>
        <Button colorScheme='red' onClick={onOpen}>
          Close position
        </Button>
      </ScaleFade>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Close position
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure to close position?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Keep
              </Button>
              <Button colorScheme='red' onClick={effect_and_close} ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
