import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginIndex from 'login/login_index'

import { useState, useEffect } from 'react';
import store, { persistor } from 'store/index'
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";
import ProtectedRoute from "./modules/protectedRoutes";

import QuickTraderProvider from 'modules/quicktrader_context'
import AppPage from 'appPage'

// disable log, error and debug in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

// Page selector using conditional rendering

// Application main
const App = () => {

  return (
    
    <QuickTraderProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>

        <Router>
          <div>
            <Routes>
              <Route exact path="/login" element={<LoginIndex/>} />
              <Route exact path="/" element={<ProtectedRoute/>} >
                <Route exact path='/' element={<AppPage/>} />
              </Route>
            </Routes>
          </div>
        </Router>

      </PersistGate>
    </Provider>
    </QuickTraderProvider>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);
