import { DatabaseServer } from 'modules/database';

import ReconnectingEventSource from "reconnecting-eventsource";

import { 
  useEffect, 
  useContext, 
  createContext,
  useState,
  useCallback,
  useRef
} from 'react';

/* Source interface */

// define general event source
function usePrimiteEventSource(url) {

  const [callbacks, setCallbacks] = useState({});
  const eventSource = useRef(null);
  
  useEffect(() => {

    console.log("Initialize url: ", url)

    var source = new ReconnectingEventSource(url, {

      // indicating if CORS should be set to include credentials, default `false`
      withCredentials: false,

      // the maximum time to wait before attempting to reconnect in ms, default `3000`
      // note: wait time is randomised to prevent all clients from attempting to reconnect simultaneously
      max_retry_time: 3000,

      // underlying EventSource class, default `EventSource`
      eventSourceClass: EventSource,
    });
    
    source.onerror = (error) => {
      console.error(error)

      // ask to reload page
      //window.location.reload();
      //source.close();
    }

    source.addEventListener('stream-reset', (event) => {

      console.log("Reconnection of: ", url)
      // ... client fell behind, reinitialize ...
    }, false);

    const cleanup = () => {

      console.log("Finalize url: ", url)

      source.close();
      eventSource.current = null;
    };
    
    // set source
    eventSource.current = source;

    window.addEventListener('beforeunload', cleanup);

    return cleanup;

  }, [url]);

  const addEventCallback = useCallback((fct_name, fct) => {
  
    // new function in specified eventsource
    setCallbacks((calls) => {

      calls[fct_name] = fct;
      return calls;
    });
  }, [setCallbacks]);

  const removeEventCallback = useCallback((fct_name) => {
  
    // remove existing function
    setCallbacks((calls) => {

      delete calls[fct_name];
      return calls;
    });

  },  [setCallbacks])
  
  // update callbacks
  useEffect(() => {

    if (!eventSource.current) {
      return;
    }

    console.log("Updating event source: ", url)
    
    //setEventSource((source) => {

    eventSource.current.onmessage = (event) => {
    
      const data = JSON.parse(event.data);
    
      // call all functions
      for (const [fct_name, fct] of Object.entries(callbacks)) {
        fct(data);
     }
    
    };

    //return source;
    //});

  }, [callbacks]);


  return {
    url,
    callbacks,
    addEventCallback,
    removeEventCallback
  };

}


/* Create context */
  
// TODO: separe in 4 different contexts
export const EventSourceContext = createContext({});

export function EventSourceProvider({children}) {

  const server = useContext(DatabaseServer);

  const transactions = usePrimiteEventSource(`${server}transactions/events/`);
  const bonds = usePrimiteEventSource(`${server}transactions/bonds/events/`);
  const brokers = usePrimiteEventSource(`${server}transactions/brokers/events/`);
  const bbmsync = usePrimiteEventSource(`${server}bbmsync/events/`);

  return (
    <EventSourceContext.Provider value={{
      transactions,
      bonds,
      brokers,
      bbmsync
    }}>
      {children}
    </EventSourceContext.Provider>
  );
}




/* Define hooks accessing the sources */


export function useEventSource(source, callback) {

  const eventRegister = useContext(EventSourceContext);

  useEffect(() => {

    
    // generate unique id
    var callback_id = 'id_' + (new Date()).getTime();

    // safety layer: check key existence
    while (callback_id in eventRegister[source].callbacks) {
      callback_id = callback_id + Object.keys(eventRegister[source].callbacks).length;
    }

    // add to queue
    console.log("Source: ", source, ". Initialize callback: ", callback_id)
    eventRegister[source].addEventCallback(callback_id, callback);
    
    return () => {

      // remove from queue
      console.log("Source: ", source, ". Finalize callback: ", callback_id)
      eventRegister[source].removeEventCallback(callback_id);
    };

  }, [source, callback]);
}



export function useTransactionsSource(callback) {

  useEventSource(`transactions`, callback);
}


export function useBondsSource(callback) {
  
  useEventSource(`bonds`, callback);
}


export function useBrokersSource(callback) {
  
  useEventSource(`brokers`, callback);
}


export function useBbmSyncSource(callback) {
  
  useEventSource(`bbmsync`, callback);
}

