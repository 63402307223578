import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import AggregatorsView from './aggregators_view'
import { 
  DatabaseServer, 
  load_positioning, 
  changeDateFormats,
  fetch_json_check
} from 'modules/database';

import BuySellView from './buysell_view'

import BondSelector from './bond_selector'
import UserSelector from './user_selector'
import MarketBidSelector from './market_bid_selector'
import IssuePriceSelector from './issue_price_selector'
import ClosePositionButton from './close_position_button'

import { useSelector, useDispatch } from "react-redux";

import loadersSlice from "../store/slices/loaders";

import {
  useTransactionsSource,
  useBondsSource,
  useBrokersSource
} from 'modules/eventsources';

import PositioningTable from './positioning_table';

import {
  HStack,
  Flex,
  StackDivider,
  Box
} from '@chakra-ui/react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function PositioningSection() {

  /* Store table */
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState();
  const [aggData, setAggData] = useState();

  // filters selectors
  const dateRange = useSelector(state => state.dateRange);
  const bond_name = useSelector(state => state.loaders.bond);
  const broker = useSelector(state => state.loaders.broker);

  // issued price when it's possible to affect it
  const [issuePrice, setIssuePrice] = useState(null);

  // temporary market price in order to make estimates
  const [tempMarketBid, setTempMarketBid] = useState(null);

  // user state
  const [user, setUser] = useState(null);
  
  // YEEEE
  const server = useContext(DatabaseServer);


  const reloadPosition = useCallback(() => {


    // filters
    const filters = {
      //dateRange : { 
      //  startDate : dateFromDB(dateRange.startDate), 
      //  endDate : dateFromDB(dateRange.endDate)
      //},
      issue_price : issuePrice,
      user,
      bond_name,
      market_bid : tempMarketBid
      //broker : broker
    }

    console.log("Reload position: ", server)

    // reset data from server
    fetch_json_check(() => load_positioning(server, filters))
      .then((data) => {
    
       data['rows'].forEach(changeDateFormats);  
    
       // reset data
       setRowData(data['rows']);
       setAggData(data['agg']);
    
       console.log("Position loaded: ", data)
     })
    .catch((err) => console.error("Could not load data from database.\n", err));

  }, [server, bond_name, tempMarketBid, issuePrice, user]);


  // reset auxiliary data on name change
  useEffect(() => {
    setIssuePrice(null);
    setTempMarketBid(null);
  }, [bond_name]);


  // react to changes
  useEffect(() => {

    reloadPosition();

  }, [reloadPosition]);


  const updateTableWithTransaction = useCallback((event) => {

    if (event.data.bond_name === bond_name) {
      // refresh if the bond is the one concerned
      reloadPosition();
    }

  }, [bond_name, reloadPosition]);


  const updateTableWithBond = useCallback((event) => {

    if (event.data.name !== bond_name) {
      // not concerned
      return;
    }


    switch (event.action) {
      case 'update':


        console.log("Bond updated: ", event)

        // refresh if the selected bond changed something which is not its name / isin
        if (event.changed.includes('market_bid')) {

          // set and automatic reload
          reloadPosition();

        } else if (event.changed.includes('issue_price')) {
          
          // all transactions changed
          reloadPosition();

        } else if (event.changed.includes('name') || event.changed.includes('isin')) {

          // TODO: set name and isin 
        }

        break;

      case 'delete':

        // notify user and keep view active
        toast.warn("This bond was deleted. Re-initializing view.")

        break;
    }
    
  }, [bond_name, reloadPosition]);


  const updateTableWithBroker = useCallback((event) => {
    switch (event.action) {

      case 'update':

        // request concerned rows
        var concernedNodes = [];

        gridRef.current.api.forEachNode((node) => {
          
          // check bond id is the concerned one
          if (node.data.broker === event.data.id) {
            
            const {broker_name, ...other_data} = node.data;

            concernedNodes.push({
              broker_name : event.data.broker_name,
              ...other_data
            }); 
          }

        });

        // update table
        gridRef.current.applyTransaction({ update : concernedNodes });

        break;
    }
    

  }, [reloadPosition]);

  useTransactionsSource(updateTableWithTransaction)
  useBondsSource(updateTableWithBond)
  useBrokersSource(updateTableWithBroker)

  const dispatch = useDispatch();

  // speculative state
  const speculative = aggData ? aggData.issue_price !== null : true;

  function setBond(state) {

    console.log("Storing bond name: ", state)

    // store bond name on slice
    dispatch(
      loadersSlice.actions.setBond(state)
    );
  }

  const resizeTable = useCallback(() => {

    const DEFAULT_DISPLAY_COLS = 8;
    const DEFAULT_SIZE_COLS = 150;

    if (document.getElementById("posTableContainer").offsetWidth > DEFAULT_SIZE_COLS * DEFAULT_DISPLAY_COLS) {
      gridRef.current.api.sizeColumnsToFit();
    }

  }, []);
  
  const onGridReady = useCallback(() => {
    // resize columns to fit view
    resizeTable();

  }, [resizeTable]);
  
  useEffect(() => {

    window.addEventListener('resize', resizeTable);

    return () => {

      window.removeEventListener('resize', resizeTable);
    };

  }, [resizeTable]);

  // TODO: user selector filter

	return (
    <>
      <Flex p='2'>
        <HStack 
          spacing={4}
          divider={<StackDivider borderColor='gray.200' />}
          >
          <BondSelector bond={bond_name} onBondChange={setBond} />
          <UserSelector user={user} onUserChange={setUser} />
          <MarketBidSelector 
            setTempMarketBid={setTempMarketBid} 
            speculative={speculative}
          />
          <IssuePriceSelector setIssuePrice={setIssuePrice} />
          <ClosePositionButton />
        </HStack>
      </Flex>
      <AggregatorsView
        inGrey={aggData ? aggData.issue_price === null : true} 
        aggData={aggData}
        />
      <BuySellView
        inGrey={aggData ? aggData.issue_price === null : true} 
        rowData={rowData}
        aggData={aggData}
      />
      <Box id="posTableContainer" w='100%' height= '300px' minH='300px' resize='vertical'  p='4' overflow='auto'>
        <PositioningTable ref={gridRef} rowData={rowData} onGridReady={onGridReady}/>
      </Box>
      </>
   );
}
