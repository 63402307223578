import { createContext, useContext } from 'react';
import { now, dateFromDB, dateToDB, parseTime, isDate, separeISODateTime, formatDateDB, formatTimeUser } from 'modules/datetime';

export const DatabaseServer = createContext();

export function DatabaseProvider({hostname, children}) {

  return (
    <DatabaseServer.Provider value={hostname}>
      {children}
    </DatabaseServer.Provider>
  );
}

/* Load everything from the database */
export function load_all_trades(server) {

  console.log("Loading from: ", server)

  return fetch(server + 'api/transaction/all', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  })
  .then((resp) => resp.json());
}

/* Get all non-confirmed trades TODO: change API */
export function load_trades(server, ...status) {

  // convert to comma-separated string
  const req = status.toString(); 

  return fetch(server + 'transactions/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  })
  .then((resp) => resp.json());
}

// Get all transactions in date range
export async function load_transactions_range(server, startDate, endDate) {

  if (!startDate) {
    startDate = now();
  }

  if (!endDate) {
    endDate = now();
  }

  const range = formatDateDB(startDate) + 'to' + formatDateDB(endDate);

  return fetch(server + 'transactions/range/' + range + '/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
  //.then((resp) => resp.json());
}

export async function load_positioning(server, filters) {

  var filter_map = structuredClone(filters);

  if (filters['dateRange']) {

    // replace date range 
    filter_map['startDate'] = filters.dateRange.startDate ? formatDateDB(filters.dateRange.startDate) : formatDateDB(now());
    filter_map['endDate'] = filters.dateRange.endDate ? formatDateDB(filters.dateRange.endDate) : formatDateDB(now());

    delete filter_map['dateRange'];
  }

  // remap status
  if (filters['status']) {

    filter_map['status'] = Object.entries(filters['status']).filter(([k, v]) => v === true);
    filter_map['status'] = Object.keys(Object.fromEntries(filter_map['status']))
  }

  // clean from null fields
  for (var prop in filter_map) {
    if (filter_map[prop] === null || filter_map[prop] === undefined) {
      delete filter_map[prop];
    }
  }

  console.log("Filters for positioning: ", filter_map)

  // conclude GET request
  return fetch(server + 'transactions/positioning/?' + new URLSearchParams(filter_map).toString(), {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

// get position summaries
export async function all_positions(server) {

  return fetch(server + 'transactions/allpositions/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export async function add_transaction(server, inputData, trade_side, username) {

  console.log("Input data: ", inputData)

  var inputRow = structuredClone(inputData);

  /* Complete with IDs and deduced informations */
  inputRow['trade_side'] = trade_side;  // TODO, manage trade side in the form instead of separating the buttons
  inputRow['user'] = username;

  // Store timestamps for the trade date
  var trade_date = new Date(inputData['trade_datetime']);
  inputRow['trade_datetime'] = dateToDB(trade_date)

  // Store timestamps for the settlement date
  if ('settlement_datetime' in inputData && inputData['settlement_datetime']) {

    // attach trade time, TODO: check if it works in another locale
    const iso = inputData['settlement_datetime'] + 'Z' + formatTimeUser(trade_date);
    
    console.log("Settlement date: ", inputData['settlement_datetime'])
    console.log("Settlement date iso: ", iso)

    // convert to timestamp
    var settlement_date = new Date(iso);
    inputRow['settlement_datetime'] = dateToDB(settlement_date)

  } else {
    
    // no settlement date specified
    delete inputRow['settlement_datetime'];
  }
  
  /* Post data to database */
  console.warn("POST transaction raw send: ", inputRow)   

  return fetch(server + 'transactions/', {
    method : 'POST',
    body : JSON.stringify(inputRow),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

// update values given an id
export async function update_transaction(server, id, diffdata) {

  return fetch(server + 'transactions/' + id + '/', {
    method : 'PUT',
    body : JSON.stringify(diffdata),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}


// erase transaction with given id
export async function delete_transaction(server, id) {

  return fetch(server + 'transactions/' + id + '/', {
    method : 'DELETE',
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}


// cancel transactions with given ids
export async function cancel_transactions(server, ids) {

  // TODO: use a for loop over ids
  return fetch(server + 'api/transaction/cancelAllById', {
    method : 'POST',
    body : JSON.stringify({ ids : ids}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}


export async function bond_names(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/bonds/name/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export const active_bonds = async (server) => bond_names(server);

export function bond_isins(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/bonds/isin/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export function bonds_list(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/bonds/list/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export function unmatched_bond_names(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/bonds/unmatched_name/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export function unmatched_bond_isin(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/bonds/unmatched_isin/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export function open_positions_bond(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/bonds/open/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export function load_active_bonds(server) {

  return fetch(server + 'transactions/bonds/active/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export function get_bond_or_most_recent(server, bond_name) {
  
  // always get a bond
  const request = bond_name ? 
    'transactions/bond/name/' + bond_name + '/' :
    'transactions/bond/most_recent/';

  return fetch(server + request, {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });

}

export function active_broker_names(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/brokers/name/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export function active_broker_aliases(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/brokers/alias/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}



export function brokers_list(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/brokers/list/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export async function blot_upload(server, file) {

  // create formdata
  var data = new FormData();
  //const filename = URL.createObjectURL(file);
  data.append("file", file)

  console.log("File blot: ", file)

  //return fetch(server + 'transactions/blotup/', {
  return fetch(server + 'bbmsync/blotup/', {
    method : 'POST',
    body : data,
    /*headers : { 
      "Content-Type" : 'multipart/form-data',
      "Accept" : 'multipart/form-data'
    }*/
  });
}

/*
 *  Currencies
 */

export async function currencies(server) {
  return fetch(server + 'transactions/currencies/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

/*
 *  Code editing
 */

export async function update_positioning_script(server, name, code) {
  return fetch(server + 'codes/' + name + '/', {
    method : 'PUT',
    body : JSON.stringify({'code' : code}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

export async function get_positioning_script(server, name) {
  return fetch(server + 'codes/' + name + '/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

/*
 *  Login and access
 */

export async function login(server, username, password) {
  return fetch(server + 'api/auth/login/', {
    method : 'POST',
    body : JSON.stringify({'username' : username, 'password' : password}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

/*
 * Manage date conversion from loaded data
 */

// change fields' formats
export function changeDateFormats(obj) {

   //  settlement date 
   if ('settlement_datetime' in obj) {
     obj['settlement_datetime'] = dateFromDB(obj['settlement_datetime'])
   }

   //  trade date 
   if ('trade_datetime' in obj) {
     obj['trade_datetime'] = dateFromDB(obj['trade_datetime'])
   }

   //  trade date 
   if ('modif_datetime' in obj) {
     obj['modif_datetime'] = dateFromDB(obj['modif_datetime'])
   }
}

/*
 *  Bond ISIN info and match
 */

export async function fetch_json_check(call, includeStatus = false) {

  //console.log(`Calling ${call}`)

  const response = await call();

  //console.log(`Call ${call} success`)
  
  const responseJson = await response.json();

  if (!response.ok) {

    const err = (includeStatus) ? { data : responseJson, status : response.status} : responseJson;

    return Promise.reject(err);
  }

  const resCompleted = (includeStatus) ? { data : responseJson, status : response.status} : responseJson;

  return Promise.resolve(resCompleted);

}

export async function bond_create(server, data) {

  return fetch(server + 'transactions/bond/', {
    method : 'POST',
    body : JSON.stringify(data),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

export async function bond_info(server, bond_name) {
  return fetch(server + 'transactions/bond/name/' + bond_name + '/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export async function all_bonds_isin(server) {
  return fetch(server + 'transactions/bonds/isin/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export async function closed_bonds_isin(server) {
  return fetch(server + 'transactions/bonds/closed/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}


export async function bond_isin_match(server, bond_name, isin) {
  return fetch(server + 'transactions/bond/name/' + bond_name + '/', {
    method : 'PUT',
    body : JSON.stringify({'isin' : isin}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

export async function bond_isin_replace(server, bond_isin, new_isin) {
  return fetch(server + 'transactions/bond/isin/' + bond_isin + '/', {
    method : 'PUT',
    body : JSON.stringify({'isin' : new_isin}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}



export async function bond_name_match(server, bond_isin, name) {
  return fetch(server + 'transactions/bond/isin/' + bond_isin + '/', {
    method : 'PUT',
    body : JSON.stringify({'name' : name}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}


export async function bond_rename(server, bond_name, new_name) {
  return fetch(server + 'transactions/bond/name/' + bond_name + '/', {
    method : 'PUT',
    body : JSON.stringify({'name' : new_name}),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

// general update method
export async function bond_update(server, identifier, body, by='name') {
  
  // choose the method
  const server_call = 'transactions/bond/' + by + '/' + identifier + '/';

  return fetch(server + server_call, {
    method : 'PUT',
    body : JSON.stringify(body),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

// TODO: redirect to general method
export async function bond_update_by_isin(server, bond_isin, body) {
  return fetch(server + 'transactions/bond/isin/' + bond_isin + '/', {
    method : 'PUT',
    body : JSON.stringify(body),
    headers : { 
      "Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}


// TODO: redirect to general method
export async function bond_close(server, bond_name) {

  return fetch(server + 'transactions/bond/close/' + bond_name + '/', {
    method : 'PUT',
    //body : JSON.stringify(),
    headers : { 
      //"Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

// TODO: redirect to general method
export async function bond_reopen(server, bond_isin) {

  return fetch(server + 'transactions/bond/reopen/' + bond_isin + '/', {
    method : 'PUT',
    //body : JSON.stringify(),
    headers : { 
      //"Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });
}

export async function bond_delete(server, bond_id) {
  return fetch(server + 'transactions/bond/id/' + bond_id + '/', {
    method : 'DELETE',
    //body : JSON.stringify(),
    headers : { 
      //"Content-Type" : "application/json",
      "Accept" : "application/json"
    }
  });

}

// brokers

export async function brokers_create(server, brokers, alias) {

  var responses = {data : [], ok : true, status : 200};

  for (const name of brokers) {
    
    const res = await fetch(server + 'transactions/brokers/', {
        method : 'POST',
        body : JSON.stringify({name : name, alias}),
        headers : { 
          "Content-Type" : "application/json",
          "Accept" : "application/json"
        }
    });
    
    // push response
    responses.data.push(res);

    
    // update error
    if (!res.ok) {
      // update state
      responses.ok = false;
      responses.status = res.status;
    }
  }

  return responses;
}

/*
 *  Matching calls
 */

// connection / unconnection
export async function match_records(server, blot_index, db_index, action) {

  const body = {
    blot_index,
    db_index,
    action
  };

  return fetch(server + 'transactions/matchbbm/connect/', {
      method : 'POST',
      body : JSON.stringify(body),
      headers : { 
        "Content-Type" : "application/json",
        "Accept" : "application/json"
      }
    });
}

// connection shortcuts
export async function connect_records(server, blot_index, db_index) {
  
  return match_records(server, blot_index, db_index, "CONNECT");
}

// unconnection shortcuts
export async function unconnect_records(server, blot_index, db_index) {

  return match_records(server, blot_index, db_index, "DELETE");
}

// load bonds by date range
export async function bonds_by_date_range(server, date_start, date_end) {

  const filter_map = { timestamps : [formatDateDB(date_start), formatDateDB(date_end)] }

  return fetch(server + 'transactions/bonds/daterange/?' + new URLSearchParams(filter_map).toString(), {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

export async function bonds_by_open_positions(server) {

  return fetch(server + 'transactions/bonds/openlist/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}

// look for connections and get data of matching
export async function matchbbm_search(server, names, isins) {

  const filter_map = { names, isins };

  const res = await fetch(server + 'transactions/matchbbm/?' + new URLSearchParams(filter_map).toString(), {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });

  const json = await res.json();
  
  if (res.ok) {

    // apply all conversions and adds-on
    json.forEach((bond) => {

      if (!bond.graph) {
        return bond;
      }

      bond.graph.forEach((obj) => {

        // assign id in order to have a unique identifier
        obj['id'] = obj.from === 'BLOT' ? obj.blot_index : obj.db_index;

        return obj;
      });

      // convert date formats
      bond.graph.forEach(changeDateFormats);

      return bond;
    });

    return Promise.resolve(json);

  } else {

    return Promise.reject(json);
  }
}

/*
 * Users
 */

export async function active_users(server) {
  
  // 5 hardcoded, but reasonable for most frequent
  
  return fetch(server + 'transactions/users/', {
    method : 'GET',
    headers : { 
      "Accept" : "application/json"
    }
  });
}



