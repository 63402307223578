import React, { useRef, useState, useEffect, useContext, forwardRef } from 'react';
import { Controller } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable';

import { DatabaseServer, active_bonds } from './database'


export const AutocompleteBondInput = forwardRef( ({id, control, rules}, ref) => {

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  // Use server
  const server = useContext(DatabaseServer);

  // Bond options
  const [options, setOptions] = useState([]);


  // fetch active bonds
  useEffect(() => {

    //console.log("Server change effect")
    
    setIsLoading(true);

    active_bonds(server)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = {'value' : el, 'label' : el};
        });
        
        // set options
        setOptions(data); 
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Bond option error:", err)
      });

  }, [server])



  return (
      <Controller
        name={id}
        control={control}
        render={
          ({field: { onChange, value, name, ref }}) => (
            <CreatableSelect
              inputRef ={ref}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              options={options}
              defaultValue={options ? options[0] : ""}
              name={name} 
              value={options.find(c => c.value === value)}
              onChange={val => onChange(val ? val.value : null)}
              placeholder="Select bond" 
            />
          )}
        rules={rules}
      />
  );
});

