import React, { useState, useRef, useEffect, useContext} from 'react';

import { 
  DatabaseServer, 
  active_bonds,
  unmatched_bond_names,
  unmatched_bond_isin
} from 'modules/database';

import Select from 'react-select';

import {
  Box,
  Stack,
  Text
} from '@chakra-ui/react';


function BondSelector(props, ref) {

  // Use server
  const server = useContext(DatabaseServer);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  // Bond options
  const [bondOptions, setBondOptions] = useState([]);

  console.log("Bond selector rerender: ", bondOptions)

  // fetch active bonds
  useEffect(() => {

    console.log("Bond selector: Server change effect: ", server)

    props.optionsGet()
      .then((res) => {

        if (!res.ok) {
          return Promise.reject(res);
        }

        return res.json();
      })
      .then((data) => {
        
        // format in the accepted way
        data.forEach(function(el, index, array) {
          array[index] = {'value' : el, 'label' : el};
        });
        
        // set options
        setBondOptions(data); 

        // if available, select the first bond
        /*if (data.length != 0) {
          setSelectedBond(data[0].value);
        }*/
      })
      .catch((err) => {
        console.error("Bond option error:", err)
      });

  }, [server])

  console.log("Bond selector props: ", props)

  return (

    <Select
        ref ={ref}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        options={bondOptions}
        defaultValue={null}
        {...props}
        // control value of component
        //value={value}
        //onChange={opt => setSelectedBond(opt ? opt.value : null)}
      />
  );
}

export default React.forwardRef(BondSelector);

