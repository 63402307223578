import { useState, useMemo, useEffect, useContext, useCallback } from 'react';

import { 
  Flex, 
  Text,
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionIcon, 
  AccordionPanel, 
  Box, 
  Center, 
  Wrap, 
  WrapItem,
  HStack,
  Stack,
  Spacer,
  Checkbox
} from '@chakra-ui/react';

import TitledView from 'modules/titled_view';

import { DatabaseServer, all_positions } from 'modules/database';
import BalanceField from './balance_field';

import loadersSlice from "../store/slices/loaders";
import pageSlice from "store/slices/page";
import { useDispatch } from "react-redux";


function Legend() {

  // light position
  const longGradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', '#1CA517')

  // short position
  const shortGradient = 'linear(to-r, #FFFFFF, {color})'.replace('{color}', '#D41D2A')

  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <Flex>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              Legend
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Flex>
        <AccordionPanel pb={4}>
          <HStack>
            <TitledView title='Position entry' >
              <Flex>
                <BalanceField 
                  name='Short name'
                  isin='ISIN (optional)'
                  expiration='Expiration (years)'
                  qty_balance='Quantity balance: BUY - SELL' 
                  issue_price='Issue price'
                  currency='Curr'
                />
              </Flex>
            </TitledView >
            <Box m='5'></Box>
            <TitledView title='Colors' >
              <Flex minW='200px' p='4' m='1' borderWidth='1px' borderRadius='lg' boxShadow='dark-lg'>
                <Stack>
                  <Flex>
                    <HStack>
                      <Box w='50' h='50' p='4' m='1' borderWidth='1px' borderRadius='lg' boxShadow='dark-lg' justify='center' bgGradient={longGradient}>
                      </Box>
                      <Text fontSize='2xl'> Light: BUY > SELL</Text>
                    </HStack>
                  </Flex>
                  <Flex>
                    <HStack>
                      <Box w='50' h='50' p='4' m='1' borderWidth='1px' borderRadius='lg' boxShadow='dark-lg' justify='center' bgGradient={shortGradient}>
                      </Box>
                      <Text fontSize='2xl'> Short: SELL > BUY</Text>
                    </HStack>
                  </Flex>
                </Stack>
              </Flex>
            </TitledView >
          </HStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );	
}

function DashboardItem(props) {
  
  // adjust size here
  return (
    <WrapItem>
      <BalanceField 
        {...props}
      />
    </WrapItem>
  );
}

function ShowBalancedFilter({show, setShow}) {

  return (
    <Checkbox
      value={show}
      onChange={e => setShow(e.target.checked)}
    >
      Show balanced positions
    </Checkbox>
  );
}

export default function DashboardSection() {

  const server = useContext(DatabaseServer);

  // Query items
  const [positions, setPositions] = useState([]);
  
  // state of
  const [showBalanced, setShowBalanced] = useState(false);
  
  // initialization
  useEffect(() => {

    all_positions(server)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();

      }).then((data) => {

        // successfully loaded
        setPositions(data);

      }).catch((error) => {
        console.error("Error loading positions' summary: ", error)
      });

  }, [server]);


  // set bond in loaders
  const dispatch = useDispatch();

  function itemClicked(event, name, isin) {

    switch (event.detail) {
      case 1: {
        break;
      }
      case 2: {

        console.log("Double clicked bond: ", name);

        // Set filters to bond name
        dispatch(
          loadersSlice.actions.setBond(name)
        );

        // Change page to 1
        dispatch(
          pageSlice.actions.setPage({ page : 1 })
        );

        break;
      }
      default: {
        break;
      }
    }
  }
  
  // re-map positions to objects
  const positionObjects = useMemo(() => {

    // determine visible items
    
    const visiblePositions = (items) => {

      if (items.length === 0) {
     
        return [];
     
      } else if (showBalanced) {
        
        // show all
        console.log("All items")
        return items;
     
      } else {
     
        console.log("Filtered items")
        return items.filter(item => item.qty_balance !== 0);
      }
    }

    console.log("Re-compute position object view")

    return visiblePositions(positions).map((item) => {

      return <DashboardItem 
        key={item.name + "_" + item.isin}
        name={item.name} 
        isin={item.isin} 
        expiration={item.expiration} 
        qty_balance={item.qty_balance}
        issue_price={item.issue_price}
        currency={item.currency} 
        onClick={itemClicked}
      />});
     
  }, [showBalanced, positions]);

    
  
  return (
    <Stack spacing={4}>
      <ShowBalancedFilter
        show={showBalanced}
        setShow={setShowBalanced}
      />
      <Box w='100%' >
        <Wrap justify="center">
          {positionObjects}
        </Wrap>
      </Box>
      <Legend/>
    </Stack>
  );
}
