import { useForm } from 'react-hook-form'
import { useRef, useState, useEffect, useContext }from 'react';
import { DatabaseServer, bond_info, bond_isin_match, bond_name_match, unmatched_bond_names, unmatched_bond_isin  } from 'modules/database';

import TitledView from 'modules/titled_view';

import {
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  FocusLock,
  Popover,
  PopoverTrigger,
  useDisclosure,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  IconButton,
  Flex,
  ScaleFade,
  Spacer,
  Box,
  Text
} from '@chakra-ui/react';

import { HiOutlineSwitchHorizontal } from "react-icons/hi";

import { Controller } from 'react-hook-form'
import BondSelector from './bond_selector';


const ISINInput = ({selectedBond, control, setValue}) => {
    
  // Use server
  const server = useContext(DatabaseServer);

  const [loading, setLoading] = useState(false);

  console.log("ISIN input rerender")

  useEffect(() => {

    if (!selectedBond) {
      return;
    }
    
    console.log("Called ISIN input search, bond = ", selectedBond)
    setLoading(true);
    
    bond_info(server, selectedBond)
      .then((res) => {

        if (!res.ok) {
          return Promise.reject(res);
        }

        return res.json();
      })
      .then((data) => {
        setValue('isin', data['isin'] ? data['isin'] : "");
      })
      .catch((err) => {
        console.error("Bond ISIN error:", err)
      })
      .finally(() => {
        setLoading(false);
      });

  }, [selectedBond]);

  return (
    <Controller
      name={'isin'}
      control={control}
      render={
        ({field: { onChange, value, name, ref }}) => (
          <Input 
            ref={ref}
            name={name}
            id={'isin'}   
            value={value}
            onChange={onChange}
            placeholder="Insert ISIN"
            disabled={loading}
          />
        )}
      rules={{ required: true }}
    />
  );
}

const BondInput = ({control, setBond, getName}) => {

  function controlAndSet(opt, onChange) {
    setBond(opt ? opt.value : null);
    onChange(opt)
  }

  const server = useContext(DatabaseServer);

  async function list_unmatched() {
    console.log("Value of getName: ", getName)
    if (getName === true) {
      return unmatched_bond_names(server);
    } else {
      return unmatched_bond_isin(server);
    }
  }

  
  //style={{width: '300px'}}
  return (
    <Box minW='200px'>
    <Controller
      name={'bond'}
      control={control}
      render={
        ({field: { onChange, value, name, ref }}) => (
          <BondSelector
            ref={ref}
            id={'bond'}
            name={name}
            value={value}
            onChange={opt => controlAndSet(opt, onChange)}
            optionsGet={list_unmatched} 
          />
        )}
      rules={{ required: true }}
        />
    </Box>
  );
}

export default function BondMatchForm({submitCallback}) {

  const [successMsg, setSuccessMsg] = useState(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  
  // focus on open
  const firstFieldRef = useRef(null);

  // Use server
  const server = useContext(DatabaseServer);

  // Switch state
  const [nameToISIN, setNameToISIN] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();


  function onCancel() {
     reset();
     onClose();
  }

  function onSubmit(values) {

    console.log("Calling ISIN submit: ", values)

    async function bond_match(lhs, rhs) {
      if (nameToISIN === true) {
        return bond_isin_match(server, lhs, rhs);
      } else {
        return bond_name_match(server, lhs, rhs);
      }
    }

    const changed = (nameToISIN === true) ? "ISIN" : "name";
    const rhs = values['isin'];       // deprecated identifier isin
    const lhs = values['bond'].value;
    
    bond_match(lhs, rhs)
      .then((res) => {
    
        if (!res.ok) {
          Promise.reject(res);
          return undefined;
        }
    
        return res.json();
      })
      .then((data) => {
        console.log("New state of bond: ", data)
    
        if (submitCallback) {
          submitCallback(data);
        }
    
        setSuccessMsg("Bond " + data['name'] + ": " + changed + " changed to " + data['isin']);
      })
      .catch((err) => {
        console.error("PUT bond info error")
        setSuccessMsg("An error occured while changing " + changed + ": " + err)
      });

  }

  const BasicForm = () => {

    const [ selectedBond, setSelectedBond ] = useState();

    console.log("Basic form selected bond: ", selectedBond)
  
    return (
      <Flex>
        <HStack>  
            <TitledView title={nameToISIN === true ? "Name" : "ISIN"}>
              <BondInput control={control} setBond={setSelectedBond} getName={nameToISIN}/>
            </TitledView>
            <Spacer/>
            <IconButton aria-label='Switch' icon={<Icon as={HiOutlineSwitchHorizontal}/>} onClick={(event) => setNameToISIN(!nameToISIN)}/>
            <Spacer/>
            <TitledView title={nameToISIN === true ? "ISIN" : "Name" }>
              <FormControl>
                <ISINInput control={control} selectedBond={selectedBond} setValue={setValue}/> 
              </FormControl>
            </TitledView>
          </HStack>   
      </Flex>
    );


  }

  const FormButtons = () => {
    return (
        <ButtonGroup display='flex' justifyContent='flex-end'>
            <Button variant='outline' onClick={onCancel} >
                Cancel
            </Button>
            <Button colorScheme='teal' onClick={handleSubmit(onSubmit)}>
                Submit
            </Button>
        </ButtonGroup>
      );
  }
  

  return (
    <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onCancel}
        placement='top-end'
        closeOnBlur={false}
        >
        <PopoverTrigger>
          <Button colorScheme="teal">Fast bond ISIN match</Button>
        </PopoverTrigger>

        <PopoverContent minW={600}>
        <FocusLock returnFocus persistentFocus={false}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverHeader> Fast bond ISIN match </PopoverHeader>

            <PopoverBody>
              <BasicForm />
              {successMsg ? <Flex m='2'> <Text color='tomato' as='b'>{successMsg}</Text> </Flex> : null}
            </PopoverBody>

            <PopoverFooter
                border='0'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                pb={4}
              >
                <FormButtons onCancel={onCancel} onSubmit={onSubmit}/>
            </PopoverFooter>
          </form>

        </FocusLock>
        </PopoverContent>

      </Popover>
    );
}
