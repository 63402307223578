import { useForm } from 'react-hook-form'
import { useRef, useState, useEffect, useContext }from 'react';
import { DatabaseServer, bond_close, closed_bonds_isin, bond_reopen  } from 'modules/database';

import TitledView from 'modules/titled_view';

import {
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  FocusLock,
  Popover,
  PopoverTrigger,
  useDisclosure,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  IconButton,
  Flex,
  ScaleFade,
  Spacer,
  Box,
  Text
} from '@chakra-ui/react';

import { HiOutlineSwitchHorizontal } from "react-icons/hi";

import { Controller } from 'react-hook-form'
import BondSelector from './bond_selector';


const BondInput = ({control, getName}) => {

  // TODO: correct options
  const server = useContext(DatabaseServer);


  return (
    <Box minW='200px'>
    <Controller
      name={'bond'}
      control={control}
      render={
        ({field: { onChange, value, name, ref }}) => (
          <BondSelector
            ref={ref}
            id={'bond'}
            name={name}
            value={value}
            onChange={onChange}
            optionsGet={async () => closed_bonds_isin(server)}
          />
        )}
      rules={{ required: true }}
        />
    </Box>
  );
}

export default function BondReopen() {

  const [successMsg, setSuccessMsg] = useState(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  
  // focus on open
  const firstFieldRef = useRef(null);

  // Use server
  const server = useContext(DatabaseServer);

  // Name or ISIN
  const [nameToISIN, setNameToISIN] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();


  function onCancel() {
     reset();
     onClose();
  }

  function onSubmit(values) {

    const bond_value = values['bond'].value;

    console.log("Opening: ", bond_value)
    
    // isin
    bond_reopen(server, bond_value).
      then((res) => {
        if (!res.ok){
          return Promise.reject(res);
        }

        console.log("Successfully re-open bond: ", bond_value);

        setSuccessMsg("Position " + bond_value + " was newly open.")
        reset();
      }).
      catch((err) => {
        console.error("An error occurred while re-opening position: ", values['bond'], ", ", err);
      });
  }

  const BasicForm = () => {

    const [ selectedBond, setSelectedBond ] = useState();

    console.log("Basic form selected bond: ", selectedBond)
  
    //<Spacer/>
    //  <IconButton aria-label='Switch' icon={<Icon as={HiOutlineSwitchHorizontal}/>} onClick={(event) => setNameToISIN(!nameToISIN)}/>

    return (
      <Flex>
        <HStack>  
            <TitledView title={nameToISIN === true ? "Name" : "ISIN"}>
              <BondInput control={control} setBond={setSelectedBond} getName={nameToISIN}/>
            </TitledView>
          </HStack>   
      </Flex>
    );
  }

  const FormButtons = () => {
    return (
        <ButtonGroup display='flex' justifyContent='flex-end'>
            <Button variant='outline' onClick={onCancel} >
                Cancel action
            </Button>
            <Button colorScheme='teal' onClick={handleSubmit(onSubmit)}>
                Re-open
            </Button>
        </ButtonGroup>
      );
  }

  //minW={600}
  
  return (
    <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onCancel}
        placement='top-end'
        closeOnBlur={false}
        >
        <PopoverTrigger>
          <Button colorScheme="teal">Re-open position</Button>
        </PopoverTrigger>

        <PopoverContent >
        <FocusLock returnFocus persistentFocus={false}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverHeader> Re-open position </PopoverHeader>

            <PopoverBody>
              <BasicForm />
              {successMsg ? <Flex m='2'> <Text color='tomato' as='b'>{successMsg}</Text> </Flex> : null}
            </PopoverBody>

            <PopoverFooter
                border='0'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                pb={4}
              >
                <FormButtons onCancel={onCancel} onSubmit={onSubmit}/>
            </PopoverFooter>
          </form>

        </FocusLock>
        </PopoverContent>

      </Popover>
    );

}


