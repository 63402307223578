import { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { LocalTradesTable } from './local_trades_table';
import SubmitButton from './submit_button'
import LoadFilters from './load_filters'

import { dateFromDB } from 'modules/datetime';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Box,
  useOutsideClick
} from '@chakra-ui/react';


import Section from 'modules/section'
import { 
  DatabaseServer, 
  load_transactions_range, 
  changeDateFormats,
  fetch_json_check,
  add_transaction, 
  delete_transaction, 
  cancel_transactions 
} from 'modules/database';

import { useSelector } from "react-redux";

import {
  useTransactionsSource,
  useBondsSource,
  useBrokersSource
} from 'modules/eventsources';



export default function LocalTradesSection() {

  /* Store data */
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState();

  const server = useContext(DatabaseServer);

  // get username
  const username = useSelector(state => state.auth.account.username);

  // get dateRange
  const dateRangeStamps = useSelector(state => state.dateRange);


  /* Add a new transaction */
  const addTransactionAndUpdate = useCallback((inputData, trade_side) => {

    // Apply transaction to database
    fetch_json_check(() => add_transaction(server, inputData, trade_side, username))
      .catch((error) => {

        toast.error(`An error occured while uploading a new transaction: ${error}`);

        console.error("An error occured while uploading a new transaction to the server: ", error)
      });

  }, [server, username]);

  
  // remove transactions
  const deleteTransactions = useCallback(() => {

    const selectedData = gridRef.current.api.getSelectedRows();
    
    for (const row of selectedData) {

      fetch_json_check(() => delete_transaction(server, row.id))
        .then((data) => {

          // TODO: freeze row, prevent updates
          
        }).catch((error) => {

          toast.error(`An error occurred while canceling transaction ${row.id}: ${error}`)
          console.error("An error occurred while canceling transaction", row, ":", error)
        });
    }
    
  }, [server]);


  const gridBox = useRef();
  
  // refresh view function
  const refreshTable = () => {

    // reset data from server
    fetch_json_check(() => load_transactions_range(server, dateFromDB(dateRangeStamps.startDate), dateFromDB(dateRangeStamps.endDate)))
      .then((data) => {
      
        // apply date conversion
        // TODO: embed in call
        data.forEach(changeDateFormats);  

        // reset data
        setRowData(data);
      })
      .catch((err) => {
        toast.error(`Could not load data from database.\n ${err}`)
        console.error("Could not load data from database.\n", err)
      });

  }
  
  // manage table updates by message
  const updateTableWith = useCallback((data) => {

    switch (data.action) {
      case 'create':

        // change date format
        changeDateFormats(data.data);

        // Add to table 
        gridRef.current.api.applyTransaction({
          add: [data.data],
          addIndex: data.data.id,
        });

        break;

      case 'update':

        // check id existence
        if (gridRef.current.api.getRowNode(data.data.id)) {

          // change date format
          changeDateFormats(data.data);

          // update table 
          gridRef.current.api.applyTransaction({
            update: [data.data],
          });
        }

        break;

      case 'delete':

        // check id existence
        if (gridRef.current.api.getRowNode(data.id)) {

          // delete row
          gridRef.current.api.applyTransaction({ remove: [{id : data.id}] });
        }

        break;
    }

  });
  
  // when bonds are present in the table, values must be updated
  const updateTableWithBond = useCallback((event) => {

    switch (event.action) {
      case 'update':
        
        // check changed field is name or isin,
        if (!(event.changed.includes('name') || event.changed.includes('isin'))) {
          break;
        }

        // request concerned rows
        var concernedNodes = [];

        gridRef.current.api.forEachNode((node) => {
          
          // check bond id is the concerned one
          if (node.data.bond === event.data.id) {
            
            const {bond_name, bond_isin, ...other_data} = node.data;

            concernedNodes.push({
              bond_name : event.data.bond_name,
              bond_isin : event.data.bond_isin,
              ...other_data
            }); 
          }

        });

        // update table
        gridRef.current.applyTransaction({ update : concernedNodes });
        
        break;

      case 'delete':

        // nothing to do, on_delete=CASCADE already does the job

        break;
    }
  });

  const updateTableWithBroker = useCallback((event) => {

    switch (event.action) {
      case 'update':
        
        // request concerned rows
        var concernedNodes = [];

        gridRef.current.api.forEachNode((node) => {
          
          // check bond id is the concerned one
          if (node.data.broker === event.data.id) {
            
            const {broker_name, ...other_data} = node.data;

            concernedNodes.push({
              broker_name : event.data.broker_name,
              ...other_data
            }); 
          }

        });

        // update table
        gridRef.current.applyTransaction({ update : concernedNodes });
        

        break;
      case 'delete':
        // nothing to do, on_delete=CASCADE already does the job
        break;
    }
  });


  // refresh with change of date range
  useEffect(() => {
    refreshTable();
  }, [dateRangeStamps]);


  // refresh all cells at any row change
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells();
    }
  }, [rowData]);

  
  // message sources
  useTransactionsSource(updateTableWith);
  useBondsSource(updateTableWithBond);
  useBrokersSource(updateTableWithBroker);


  // Specific Keyboard events
  const handleKeyDown = useCallback((event) => {

    if (!event) {
      return;
    }

    const keyboardEvent = event;
    const key = event.key;

    if (key.length && key === 'Escape' && gridRef.current) {

      gridRef.current.api.deselectAll();
      gridRef.current.api.stopEditing(true);
    }

  }, []);

  useEffect(() => {
    
    // bind event listener
    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }

  }, [handleKeyDown]);
  
  // cancel button ref
  const cancelButtonRef = useRef();
  
  // table selection event
  const onSelectionChanged = useCallback(() => {

    const selectedData = gridRef.current.api.getSelectedRows();

    //if (cancelButtonRef.current.setVisible) {
    cancelButtonRef.current.setVisible((selectedData.length > 0) === true);
    //}

    //if (cancelButtonRef.current.setRowsAlertNb) {
    cancelButtonRef.current.setRowsAlertNb(selectedData.length);
    //}

  }, []);

  return (
    <>
      <LoadFilters/>
      <Box ref={gridBox} width='100%' minH='300px' height='500px' resize='vertical'  p='4' overflow='auto'>
        <LocalTradesTable 
          ref={gridRef} 
          rowData={rowData} 
          setRowData={setRowData} 
          onSelectionChanged={onSelectionChanged} 
          refresh={refreshTable}
          onGridReady={refreshTable}
        />
      </Box>
      <SubmitButton 
        addTransaction={addTransactionAndUpdate} 
        cancelTransactions={deleteTransactions} 
        cancelButtonRef={cancelButtonRef}
        refreshTable={refreshTable}
      />
    </>
   );
}
