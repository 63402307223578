import React, { useState, useContext, useEffect } from 'react';

import {
  Box,
  Center,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Input,
  Divider,
  Flex,
  IconButton,
  Spacer,
  CircularProgress
} from '@chakra-ui/react'

import { EditIcon, CheckIcon, NotAllowedIcon } from '@chakra-ui/icons'
import { PythonContext, PythonEval }  from 'modules/pythonContext'

import { DatabaseServer, update_positioning_script, get_positioning_script } from 'modules/database';

import AceEditor from 'react-ace'
import brace from 'brace';
import 'brace/mode/python'

export const twodigits_formatter = new Intl.NumberFormat("en", { maximumFractionDigits: 2 });

export const percent_formatter = new Intl.NumberFormat("de-DE", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 });

// TODO: flexible currency formatter
export const gbp_formatter = new Intl.NumberFormat("de-DE", { style: "currency", currency: "GBP" });


// Python code editor (single line)
function PythonEditor({code, setCode}) {

  const editorOptions = {
    readOnly: true, // TODO DEV phase: temporary disable
    highlightActiveLine: false,
    showPrintMargin: false
  };

  const editorOnLoad = (editor) => {
    editor.renderer.setScrollMargin(10, 10, 0, 0)
    editor.moveCursorTo(0, 0)
  }

  /*theme={colorMode === 'dark' ? 'idle_fingers' : 'textmate'}*/

  return ( 
    <AceEditor
      value={code}
      mode="python"
      name="CodeBlock"
      fontSize="0.9rem"
      className="min-h-[4rem] overflow-clip rounded shadow-md"
      onChange={(newValue) => setCode(newValue)}
      theme='textmate'
      width="100%"
      maxLines={1} // before: Infinity
      onLoad={editorOnLoad}
      editorProps={{ $blockScrolling: true }}
      setOptions={editorOptions}
    />
  );
}


// Layout for Metric Field
export function MetricFieldLayout({label, icon, editRequest, content, bg}) {
  return (
    <Stat  p='4' m='1' borderWidth='1px' borderRadius='lg'  bg={bg ? bg : null}>
      <Box w='100%'>
        <VStack>
          <HStack>
            <StatLabel>{label}</StatLabel>
            <Spacer />
            <IconButton icon={icon} onClick={editRequest} />
          </HStack>
          {content}
        </VStack>
      </Box>
    </Stat>
  );
}

// Layout for Metric Field
export function FlatFieldLayout({label, icon, editRequest, content, bg}) {
  return (
    <Stat w='90%' p='1' borderWidth='1px' borderRadius='lg' bg={bg ? bg : null}>
      <HStack>
        <StatLabel pr='4' pl='4'>{label}</StatLabel>
        {content}
        <Spacer />
        <IconButton icon={icon} onClick={editRequest} />
      </HStack>
    </Stat>
  );
}



// General class for code-modifiable field
export function EditableField({name, source, formatter, label, layout, bg}) {

  // store editing 
  const [edit, setEdit] = useState(false);

  // store unconfirmed code 
  const [code, setCode] = useState('');

  // validated status
  // TODO: optimize reloading
  const [validated, setValidated] = useState(true);

  const server = useContext(DatabaseServer);

  function editRequest() {

    if (edit === false) {
      console.log("Editing code: ", name)
      setEdit(~edit); 
      return;
    }

    // confirm code to server
    update_positioning_script(server, name, code)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();

      }).then((data) => {

        // change status
        setEdit(~edit); 

        // TODO: re-ask agg update
        // TODO (advanced): optimize by asking from execution priority
        //setValidated(false);

      }).catch((error) => {
        console.error("An error occured while updating script: ", name, error)
      });
  }

  let shown;
  let icon;

  if (edit) {
    shown = <PythonEditor code={code} setCode={setCode} />;
    icon = <CheckIcon />;
  } else {

    if (validated === true && source !== undefined) {

      // get the variable related to the name
      const variable = source[name];

      // chose between a formatted value or not available icon
      shown = (variable !== undefined) ? <StatNumber> {formatter.format(variable, source)} </StatNumber> : <NotAllowedIcon w={8} h={8} />;

    } else {

      // loading roll
      shown = <CircularProgress isIndeterminate color='blue.300' />;
    }
      
    icon = <EditIcon />;
  }
  
  // fetch code on mount
  useEffect(() => {

    // TODO: optimize this call
    // perform it only when needed
    get_positioning_script(server, name)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }

        return res.json();

      }).then((data) => {

        // get code
        setCode(data['code']);
        
      }).catch((error) => {
        console.error("An error occured while getting script info: ", name, error)
      });
  }, []);

  // Dynamic layout
  const LayoutClass = layout ? layout : MetricFieldLayout;

  return (
    <LayoutClass
      label={label}
      icon={icon}
      editRequest={editRequest}
      content={shown}
      bg={bg}
    />
  );
};


export function SimpleField({value, formatter, label}) {

  return (
    <Stat  p='6' m='2' borderWidth='1px' borderRadius='lg'>
      <Box w='100%'>
        <VStack>
          <StatLabel>{label}</StatLabel>
          <StatNumber> 
            {formatter.format(value)}
          </StatNumber>
        </VStack> </Box> </Stat>
  );
};

