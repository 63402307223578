import { useRef, useState, useEffect, useContext, forwardRef }from 'react';
import { useForm } from 'react-hook-form'

import TitledView from 'modules/titled_view'

import { AutocompleteCurrencyInput } from 'modules/autocomplete_currency_input';

import Select from 'react-select';
import { Controller } from 'react-hook-form'

import { DatabaseServer, bond_create, fetch_json_check } from 'modules/database'

import {
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  Divider,
  FocusLock,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  ScaleFade,
  Spacer
} from '@chakra-ui/react';

const requiredRule = (value) => {

  return { 
    required: {value , message : "This field is required"}
  };
};


const BondInput = forwardRef(({register, control, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.name}>
      <FormLabel>Name / ISIN</FormLabel>
      <HStack>
        <Input 
          ref={ref} 
          id={'name'} 
          placeholder='Insert name'
          {...register('name', { 
            required: requiredRule(true),
            validate : {
              notEmpty : v => v !== "" || "Can't be empty."
            }
          })
          }  
        />
        <Divider orientation='vertical'  />

        <Controller
          control={control}
          name={'isin'}
          render={
            ({field: { onChange, value, name, ref }}) => {

              const upperCaseChange = (e) => {

                e.target.value = e.target.value.toUpperCase();

                return e;
              }

              return (
                <Input 
                  ref={ref}
                  placeholder='Insert ISIN (optional)'
                  value={value}
                  onChange={e => onChange(upperCaseChange(e))}
                />
              );
            }}
       />
      </HStack>
      <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
    </FormControl>
  );
});


    
const FIXFRNInput = forwardRef(({control, errors}, ref) => {

  const options = [{'value' : 'FIX', 'label' : 'FIX'},
                   {'value' : 'FRN', 'label' : 'FRN'}];

  return (
    <FormControl isInvalid={errors.fixfrn}>
      <FormLabel>FIX / FRN</FormLabel>
      <Controller
        ref={ref}
        name={'fixfrn'}
        control={control}
        render={
        ({field: { onChange, value, name, ref }}) => (
          <Select
            ref={ref}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name={name}
            options={options}
            placeholder="FIX or FRN"
            defaultValue={options[0]}
            value={value}
            onChange={onChange}
          />
        )}
        rules={{ required : requiredRule(true) }}
    />
    <FormErrorMessage>{errors.fixfrn && errors.fixfrn.message}</FormErrorMessage>
    </FormControl>
  );
});

const TenorInput = forwardRef(({control, errors}, ref) => {

  const options = [{'value' : 'PERP', 'label' : 'PERP'},
                   {'value' : 'EXP', 'label' : 'EXP'}];

  return (
    <FormControl isInvalid={errors.tenor}>
      <FormLabel>Tenor</FormLabel>
      <Controller
        ref={ref}
        name={'tenor'}
        control={control}
        render={
        ({field: { onChange, value, name, ref }}) => (
          <Select
            ref={ref}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name={name}
            options={options}
            placeholder="PERP or EXP"
            defaultValue={options[0]}
            value={value}
            onChange={onChange}
          />
        )}
      rules={{ required : requiredRule(true) }}
    />
    <FormErrorMessage>{errors.tenor && errors.tenor.message}</FormErrorMessage>
    </FormControl>
  );
});


const ExpirationInput = forwardRef(({register, errors, tenor}, ref) => {

  return (
    <FormControl isInvalid={errors.expiration}>
      <FormLabel>Expiration (years)</FormLabel>
      <NumberInput
        ref={ref}
        precision={0}
        min={1}
        defaultValue={1}
      >
        <NumberInputField 
          id={'expiration'} 
          placeholder={'Expiration (in years)'} 
          {...register('expiration', {required : requiredRule(tenor === "EXP") })}  
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{errors.expiration && errors.expiration.message}</FormErrorMessage>
    </FormControl>
  );
});


const IssuePriceInput = forwardRef(({register, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.issue_price}>
      <FormLabel>Issue price</FormLabel>
      <NumberInput
        ref={ref}
        precision={3}
        min={0}
      >
        <NumberInputField 
          id={'issue_price'} 
          placeholder={'Issue price (optional)'} 
          {...register('issue_price', {
              validate : { 
                positive : v => !v || parseInt(v) > 0 || "Can't be zero (> 0)."
              }         
            } 
          )}  
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{errors.issue_price && errors.issue_price.message}</FormErrorMessage>
    </FormControl>
  );
})

const YieldInput = forwardRef(({register, errors, fixfrn}, ref) => {

  // TODO: required if visible

  return (
    <FormControl isInvalid={errors.yield_value}>
      <FormLabel>Yield value (%)</FormLabel>
      <NumberInput
        ref={ref}
        precision={3}
        min={0}
      >
        <NumberInputField 
          id={'yield_value'} 
          placeholder={'Yield value (optional)'} 
          {...register('yield_value', {
              validate : { 
                positive : v => !v || parseInt(v) > 0 || "Can't be zero (> 0)."
              }         
            })
          }  
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{errors.yield_value && errors.yield_value.message}</FormErrorMessage>
    </FormControl>
  );
});


const YieldPeriodInput = forwardRef(({register, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.yield_period}>
      <FormLabel> </FormLabel>
      <NumberInput
        ref={ref}
        precision={0}
        min={1}
      >
        <NumberInputField 
          id={'yield_period'} 
          placeholder={'Yield period'} 
          {...register('yield_period', {
            validate : { 
                positive : v => !v || parseInt(v) > 0 || "Minimum value is 1."
              } 
          })}  
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{errors.yield_period && errors.yield_period.message}</FormErrorMessage>
    </FormControl>
  );
});

// TODO: select input
const CurrencyInput = forwardRef(({control, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.currency}>
      <FormLabel>Currency</FormLabel>
      <AutocompleteCurrencyInput 
        ref={ref}
        id={'currency'}
        control={control}
        rules={{ required : requiredRule(true) }}
      />
      <FormErrorMessage>{errors.currency && errors.currency.message}</FormErrorMessage>
    </FormControl>
  );
});



export default function BondCreateForm() {

  /* Fields */

  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef(null);

  
  /* Form */
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      fixfrn: {'value' : 'FIX', 'label' : 'FIX'},
      tenor: {'value' : 'PERP', 'label' : 'PERP'},
      currency : 'GBP'
    }
  });

  /*
   * Inputs
   */

  const FormStructure = () => {
    
    // track changes of FIX/FRN for conditional rendering
    const value_of_fix_frn = watch("fixfrn");
    const value_of_tenor = watch("tenor");

    return (
      <Stack spacing={4}>
        <BondInput 
          ref={firstFieldRef}
          register={register}
          control={control}
          errors={errors}
        />
        <HStack spacing={4}>
          <FIXFRNInput 
            control={control}
            errors={errors}
          />
          {(value_of_fix_frn && value_of_fix_frn.value === "FIX") ?
            <YieldInput
              register={register}
              errors={errors}
              fixfrn={value_of_fix_frn}
            />
            : null
          }
        </HStack>
        <HStack spacing={4}>
          <TenorInput
            control={control}
            errors={errors}
          />
          {(value_of_tenor && value_of_tenor.value === "EXP") ?
            <ExpirationInput
              register={register}
              errors={errors}
              tenor={value_of_tenor}
            />
            : null
          }
        </HStack>
        <HStack spacing={4}>
          <IssuePriceInput
            register={register}
            errors={errors}
          />
          <CurrencyInput
            control={control}
            errors={errors}
          />
        </HStack>
      </Stack>
    )
  }
  
  // handle cancelling
  function onCancel() {
     reset();
     onClose();
  }

  const server = useContext(DatabaseServer);

  function onSubmit(values) {

    // fix rate_type
    values['rate_type'] = values['fixfrn'].value;
    delete values.fixfrn;

    // fix tenor
    values['tenor'] = values['tenor'].value;
    
    // manage yield_value
    if (!values.yield_value) {
      delete values.yield_value;
    }

    if (values.isin !== undefined && values.isin === "") {
      delete values.isin;
    }

    if (values.issue_price !== undefined && values.issue_price === "") {
      delete values.issue_price;
    }

    console.log("Bond creation form: ", values)

    // fetch server 
    fetch_json_check(async () => bond_create(server, values)).
      then((data) => {

        console.log("Bond created: ", data);

        reset();
        onClose();
      }).
      catch((err) => {
        console.error("Error on submitting form for bond creation: ", err)
      });
  }

  const FormButtons = () => {
    return (
        <ButtonGroup display='flex' justifyContent='flex-end'>
            <Button variant='outline' onClick={onCancel} >
                Cancel
            </Button>
            <Button colorScheme='teal' onClick={handleSubmit(onSubmit)}>
                Submit
            </Button>
        </ButtonGroup>
      );
  }


  return (
    <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onCancel}
        placement='top-start'
        closeOnBlur={false}
        isLazy
        >
        <PopoverTrigger>
          <Button colorScheme='purple'>Register bond</Button>
        </PopoverTrigger>

        <PopoverContent w={400}>
        <FocusLock returnFocus persistentFocus={false}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverHeader> Insert bond info </PopoverHeader>
            <PopoverBody>
              <FormStructure/>
            </PopoverBody>

            <PopoverFooter
                border='0'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                pb={4}
              >
                <FormButtons />
            </PopoverFooter>
          </form>

        </FocusLock>
        </PopoverContent>

      </Popover>
    );

}

