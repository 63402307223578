import React, { useState, useEffect } from 'react';

import {
  Box,
  StatGroup,
  Wrap, 
  WrapItem
} from '@chakra-ui/react'

import { EditableField, twodigits_formatter, gbp_formatter } from './aggregation'

export default function AggregatorsView({aggData, inGrey}) {

  const grey_sign = (inGrey) ? ' (diff.)' : '';
  const grey_bg = inGrey ? '#E0E0E0' : '#CCE5FF';

  return (
    <Box w='100%' >
      <StatGroup>
        <EditableField 
          label={'Overall P&L' + grey_sign} 
          source={aggData}
          name={'overall_pnl'}
          formatter={twodigits_formatter}
          bg={grey_bg}
        />
        <EditableField 
          name={'balance_qty'}
          label={'Quantity Balance'} 
          source={aggData}
          formatter={gbp_formatter}
        />
        <EditableField
          name={'temp_pnl'}
          label={'Temporary P&L'}
          source={aggData}
          formatter={twodigits_formatter}
        />
        <EditableField 
          label={'Temporary Imbalance' + grey_sign} 
          name={'temp_imbalance'}
          source={aggData}
          formatter={twodigits_formatter}
          bg={grey_bg}
        />
      </StatGroup>
    </Box>
  );


}
