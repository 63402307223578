import React, { useRef } from 'react';

import MenuTrigger from './menutrigger'
import MenuNav from './menunav'

import {
  Box,
  Flex,
  HStack,
  useColorModeValue,
  SlideFade,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react';



export default function SideMenu() {

  // menu open/close state with reference to flex
  const ref = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // close when click outside
  useOutsideClick({
    ref: ref,
    handler: onClose,
  })

  //
  //pos="fixed"
  return (
    <Flex
      ref={ref}
      as="nav"
      zIndex="sticky"
      bg={useColorModeValue('white', 'gray.800')}
      borderRadius='10'
    >
      <HStack  alignItems="flex-start" justify="space-between">
        <MenuTrigger isOpen={isOpen} onOpen={onOpen} onClose={onClose}/> 
        <SlideFade in={isOpen} unmountOnExit={true}>
          <HStack  alignItems="flex-start" justify="space-between">
            <MenuNav onClose={onClose}/>
          </HStack>
        </SlideFade>
      </HStack>
    </Flex>
  );
}

