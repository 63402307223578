import { useRef, useState, useEffect, useContext, forwardRef }from 'react';
import { useForm } from 'react-hook-form'
import { isoToday, formatLocal, dateToDatetime, geDates } from 'modules/datetime'

import { Currency } from "modules/currency"
import TitledView from 'modules/titled_view'

import MarketPriceInput, { ControlledMarketPriceInput  } from 'modules/market_price_input'

import {
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  FocusLock,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  ScaleFade,
  Spacer,
  Text
} from '@chakra-ui/react';

import { AutocompleteBondInput } from 'modules/autocomplete_bond_input';
import { AutocompleteBrokerInput } from 'modules/autocomplete_broker_input';


const BondInput = forwardRef(({control, errors}, ref) => {
    
  return (
    <FormControl isInvalid={errors.bond_name}>
      <FormLabel>Bond name</FormLabel>
      <AutocompleteBondInput 
        ref={ref} 
        id={'bond_name'}
        control={control}
        rules={ {required : {value : true, message : "This field is required."}} }
      />
      <FormErrorMessage>{errors.bond_name && errors.bond_name.message}</FormErrorMessage>
    </FormControl>
  );
});


const BrokerInput = forwardRef(({control, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.broker_name}>
      <FormLabel>Broker name</FormLabel>
      <AutocompleteBrokerInput 
        ref={ref} 
        id={'broker_name'}
        control={control}
        rules={ {required : {value : true, message : "This field is required."}} }
      />
      <FormErrorMessage>{errors.broker_name && errors.broker_name.message}</FormErrorMessage>
    </FormControl>
  )
});

const QuantityInput = forwardRef(({register, errors}, ref) => {
    
    return (
      <FormControl isInvalid={errors.quantity}>
        <FormLabel>Quantity (M)</FormLabel>
        <NumberInput 
          ref={ref}  
          precision={2}
          defaultValue={0}
          min={0}
        >
          <NumberInputField 
            id={'quantity'} 
            {...register('quantity', { 
              required: { value : true, message : "This field is required." },
              validate : {
                positive : v => parseInt(v) > 0 || "Can't be zero (> 0)"
              }
            })}
            />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{errors.quantity && errors.quantity.message}</FormErrorMessage>
      </FormControl>
      );
  });


    
// market price
const MktPriceInput = forwardRef(({control, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.market_price}>
      <FormLabel>Market Price</FormLabel>
      <ControlledMarketPriceInput 
        ref={ref}
        id='market_price'
        control={control}
        rules ={{ required: { value: true, message : "This field is required." }}}
      />
      <FormErrorMessage>{errors.market_price && errors.market_price.message}</FormErrorMessage>
    </FormControl>
  );
});

const TradeDateInput = forwardRef(({register, errors}, ref) => {

  return (
    <FormControl isInvalid={errors.trade_datetime}>
      <FormLabel>Trade date and time</FormLabel>
      <Input 
        ref={ref} 
        id={'trade_datetime'} 
        type="datetime-local" 
        {...register('trade_datetime', { required: { value: true, message : "This field is required." }})}  
      />
      <FormErrorMessage>{errors.trade_datetime && errors.trade_datetime.message}</FormErrorMessage>
    </FormControl>
  );
});


const SettlementDateInput = forwardRef(({register, watch, errors}, ref) => {

  const trade_datetime = new Date(watch("trade_datetime"));
  
  return (
    <FormControl isInvalid={errors.settlement_datetime}>
      <FormLabel>Settlement date</FormLabel>
      <Input 
        ref={ref} 
        id={'settlement_datetime'} 
        type="date" 
        {...register('settlement_datetime',
          { validate : {
            greateThanTrade : v => !v || (v && geDates(dateToDatetime(v, trade_datetime), trade_datetime)) || "Must be greater than trade date." 
          }
          }
        )}  
      />
      <FormErrorMessage>{errors.settlement_datetime && errors.settlement_datetime.message}</FormErrorMessage>
    </FormControl>
  );
});



export default function LocalTradesForm({label, colorScheme, addTransaction}) {

  /* Fields */

  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef(null);

  /* Form */
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues : {
      market_price : 0.000,
      quantity : 0,
      trade_datetime : formatLocal(new Date())
    }
  });
  
  console.log("Datetime: ", watch("trade_datetime"))

  /* Functions */

  const BasicForm = ({ firstFieldRef, control}) => {


    return (
      <Stack spacing={4}>
        <HStack>
          <BondInput
            ref={firstFieldRef}
            control={control}
            errors={errors}
          />
          
          <BrokerInput
            control={control}
            errors={errors}
          />
        </HStack>

        <MktPriceInput 
          control={control}
          errors={errors}
        />

        <QuantityInput 
          register={register}
          errors={errors}
        />
      </Stack>
    )
  }

  const AdvancedForm = () => {

    return (
      <Stack spacing={4}>
        <TradeDateInput 
          register={register}
          errors={errors}
        />
        <SettlementDateInput 
          register={register}
          errors={errors}
          watch={watch}
        />
      </Stack>
    )

  }

  function onCancel() {

     reset();
     onClose();
  }

  function onSubmit(values) {

    // Add a row
    addTransaction(values);

    reset();
    onClose();
  }

  useEffect(() => {
    console.log("Form errors: ", errors);
  }, [errors]);

  const FormButtons = () => {
    return (
        <ButtonGroup display='flex' justifyContent='flex-end'>
            <Button variant='outline' onClick={onCancel} >
                Cancel
            </Button>
            <Button colorScheme='teal' onClick={handleSubmit(onSubmit)}>
                Submit
            </Button>
        </ButtonGroup>
      );
  }

  return (
    <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onCancel}
        placement='top-start'
        closeOnBlur={false}
        isLazy
        >
        <PopoverTrigger>
          <Button colorScheme={colorScheme}>New {label}</Button>
        </PopoverTrigger>

        <PopoverContent w={400}>
        <FocusLock returnFocus persistentFocus={false}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverHeader> Insert {label} info </PopoverHeader>
            <PopoverBody>
              <BasicForm firstFieldRef={firstFieldRef} control={control}/>
            </PopoverBody>

            <Accordion allowToggle>
            <AccordionItem>

              <AccordionButton>
                <PopoverHeader> Advanced options </PopoverHeader>
                <AccordionIcon/>
              </AccordionButton>

              <AccordionPanel>
                <PopoverBody>
                  <AdvancedForm  />
                </PopoverBody>
              </AccordionPanel>
            </AccordionItem>
            </Accordion>

            <PopoverFooter
                border='0'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                pb={4}
              >
                <FormButtons onCancel={onCancel} onSubmit={onSubmit}/>
            </PopoverFooter>
          </form>

        </FocusLock>
        </PopoverContent>

      </Popover>
    );
}
